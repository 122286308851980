.newchatbotcontainer{
    position: absolute;
    width: 77%;
    height: 90vh;
    background-color:#031720;
    box-shadow: 2px 2px 15px -3px black;
    border-radius: .4vw;
    margin-left: 20%;
    margin-top: 5vh;  
}

.newchatbotinfo{
    width: 96%;
    height: auto;
    color: white;
    padding: 2%;
    font-size: 1.5vw;

}
.newchatbotdetails{
    margin-top: 2%;
}
.newchatbotinputlables{
    font-size: 1.4vw;
    color: #16AEE8;
}
.newchatbotinputs{
    all: unset;
    background-color: transparent;
    color: #16AEE8;
    font-size: 1.4vw;
    border: 1px solid #16AEE8;
    border-radius: .4vw;
    width: 70%;
    padding: 1%;
    margin-top: 1%;

}
.newchatbotinputs::placeholder{
    color: #16AEE8;
    font-size: 1.4vw;
}
.createchatbotbtn{
    background-color: #16AEE8;
    border-radius: .5vw;
    color: white;
    padding: 1%;
    width: auto;
    min-width: 25%;
    height: auto;
    margin-left: 2%;
}
.generatedscripttaglabel{
    font-size: 1.4vw;
    margin-top: 4%;
}
.generatedseripttag{
    width: 95%;
    height: auto;
    padding: 2%;
    font-size: 1.24vw;
    background-color: white;
    color: black;
    cursor: pointer;

}
.newloader {
height: 2.5vw;
width: 2.5vw;
animation: rotate 3s infinite linear forwards;
}
@keyframes rotate {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
    
}
.testconnectionbtn{

    width: 30%;
    height: auto;
    background-color: #16AEE8;
    padding: 1%;
    font-size: 1.4vw;
    border-radius: .7vw;
    margin-top: 3%;
}
.testconnectionbtn:hover {
  background-color: #084a68;
  transition-duration: .3s;
  
}
.createchatbotbtn:hover {
    background-color: #084a68;
    transition-duration: .3s;
  }
  .connectionstatus{
    width: 30%;
    height: auto;
    padding: 1%;
    font-size: 1.4vw;
    border-radius: .7vw;
    margin-top: 3%;
    margin-left: 4%;
  }

  @media screen and (max-width:412px) {
    .newchatbotcontainer{
        position: fixed;
        width: 100%;
        height: 80vh;
        background-color:transparent;
        box-shadow: none;
        border-radius: .4vw;
        margin-left: 0;
        top: 20vh;  
    }
    
    .newchatbotinfo{
        width: 96%;
        height: auto;
        color: #031720;
        padding: 2%;
        font-size: 4vw;
        font-weight: bold;
    
    }
    .newchatbotdetails{
        margin-top: 2%;
        font-weight: 600;
    }
    .newchatbotinputlables{
        font-size: 4vw;
        color: #16AEE8;
        font-weight: 600;
    }
    .newchatbotinputs{
        all: unset;
        background-color: transparent;
        color: #16AEE8;
        font-size: 3vw;
        border: 1px solid #16AEE8;
        border-radius: 2vw;
        width: 93%;
        padding: 3%;
        margin-top: 4%;
        font-weight: 600;
    }

    .newchatbotinputs::placeholder{
        color: #16AEE8;
        font-size: 3vw;
        font-weight: 600;
    }
    .createchatbotbtn{
        background-color: #16AEE8;
        border-radius: 2vw;
        color: white;
        padding: 1%;
        width: auto;
        min-width: 40%;
        height: auto;
        margin-top: 2vh;
        margin-left: 2%;
    }
    .generatedscripttaglabel{
        font-size: 4vw;
        margin-top: 4%;
    }
    .generatedseripttag{
        width: 95%;
        height: auto;
        padding: 2%;
        font-size: 3vw;
        background-color: black;
        color: white;
        cursor: pointer;
        margin-top: 5%;
    
    }
    .newloader {
    height: 4vw;
    width: 4vw;
    animation: rotate 3s infinite linear forwards;
    }
    @keyframes rotate {
        0% { transform: rotate(0deg); }
        100% { transform: rotate(360deg); }
        
    }
    .testconnectionbtn{
    
        width: 40%;
        height: auto;
        background-color: #16AEE8;
        padding: 1%;
        font-size: 4vw;
        border-radius: 2vw;
        margin-top: 8%;
        margin-left: 3%;
    }
    .testconnectionbtn:hover {
      background-color: #084a68;
      transition-duration: .3s;
      
    }
    .createchatbotbtn:hover {
        background-color: #084a68;
        transition-duration: .3s;
      }
      .connectionstatus{
        width: auto;
        height: auto;
        background-color: #16AEE8;
        padding: 1%;
        font-size: 4vw;
        border-radius: 2vw;
        margin-top: 8%;
        margin-left: 3%;
      }
    
  }