.featureshead{
    position: absolute;
    margin-top: 370vh;
    font-size: 4vw;
    font-family: sans-serif;
    color: #16AEE8;
    display: flex;
    text-align: center;
    align-items: center;
    justify-content: center;
    margin-left: 43%;
}

.featuresbox{
    position: absolute;
    width: 100%;
    height: 70vh;
    background-color: #032530;
    margin-top: 390vh;
}
