@media screen and (max-width:412px) {
    .mychatbots{
        margin-top: 10vh;
        box-shadow: none;
    }
    .removebtn{

    }
    .mychatbotcontainer{
        position: fixed;
        top: 5%;
        left: -4%;
        width: 100%;
        height: auto;
        box-shadow: none;
    }
    .mychatbottablehead{
        background-color:#030e12 !important;
    }
}