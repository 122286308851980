.templatebox{
    width: 50%;
    height: 80vh;
    position: absolute;
    margin: 2%;
    margin-top: 6%;
    margin-left: 20%;
    padding: 1%;
    border-radius: .4vw;
    box-shadow: 2px 2px 12px -2px black;
    background-color: #f0fbff;
    animation: showtemplateviewer .4s ease-in-out forwards;
}
.mytemplateshead{
    font-size: 2vw;
    color: #053548;
    margin-bottom: 2%;
    font-weight: bolder;
}
.viewtemplatebtn{
    width: 90%;
    height: 3vw;
    display: flex;
    text-align: center;
    align-items: center;
    justify-content: center;
    font-size: 1.5vw;
    margin-left: 10%;
    cursor: pointer;
}
.templateviewbox{
    overflow: scroll;
    position: absolute;
    width: 25%;
    margin-left: 74%;
    margin-top: 6%;
    height: 84vh;
    border-radius: .4vw;
    background-color: #053548;
    box-shadow: 2px 2px 12px -2px black;
    animation: showtemplateviewer2 .4s ease-in-out forwards;

}
@keyframes showtemplateviewer {
     from{
    
     opacity: 0;
     }
     to{
   
      opacity: 1;
     } 
}
@keyframes showtemplateviewer2 {
    from{
    transform: translateY(-20%);
  
    }
    to{
     transform: translateY(0%);
    
    } 
}
.temprow{
    font-size: 1.2vw;
    font-weight: bold;
}
.templateshowicon{
    height: 2.5vw;
    width: 2.5vw;
}
.deletetempicon{
  height: 3vw;
  width: 3vw;
  margin-left: 10%;

}
.edittempicon{
    height: 2.3vw;
    width: 2.3vw;
    margin-left: 10%;
}
.tempmetainfo{
    padding: 5%;
    font-size: 1.4vw;
    color:white
}
.previewbox{
    margin-top: 2%;
    padding: 2%;
    width: 97%;
    height: auto;
    border-radius: .4vw;
    background-color: white;

}
.compheader{
    font-size: 1.5vw;
    font-weight: bolder;
    color: black;
}
.compmessage{
    font-size: 1.5vw;
    color: black;
}
.tempbutton{
    width: 101.5%;
    height: 3vw;
    margin-top: 2%;
    display: flex;
    text-align: center;
    align-items: center;
    justify-content: center;
    font-size: 1.5vw;
    border-radius: .3vw;
    color:#16AEE8;
    background-color: white;
    border-top: 1px solid rgb(198, 198, 198);
}
.notemplates{
    width: 70%;
    height: 4vw;
    font-size: 1.6vw;
    font-weight: bold;
    display: flex;
    text-align: center;
    align-items: center;
    justify-content: center;
    padding: 1%;
    border-radius: .7vw;
    color: white;
    margin-top: 20%;
    margin-left: 15%;
    background-color: #387189;
}
@media screen and (max-width:412px){
    .templatebox{
        width: 68%;
        height: auto;
        position: absolute;
        margin: 2%;
        margin-top: 35%;
        margin-left: 2%;
        padding: 1%;
        border-radius: .4vw;
        box-shadow: 2px 2px 12px -2px black;
        background-color: #f0fbff;
        animation: showtemplateviewer .4s ease-in-out forwards;
    }
    .mytemplateshead{
        font-size: 4vw;
        color: #053548;
        margin-bottom: 2%;
        font-weight: bolder;
    }
    .viewtemplatebtn{
        width: 90%;
        height: 3vw;
        display: flex;
        text-align: center;
        align-items: center;
        justify-content: center;
        font-size: 1.5vw;
        margin-left: 10%;
        cursor: pointer;
    }
    .templateviewbox{
        overflow: scroll;
        position: absolute;
        width: 25%;
        margin-left: 74%;
        margin-top: 35%;
        height: auto;
        border-radius: .4vw;
        background-color: #053548;
        box-shadow: 2px 2px 12px -2px black;
        animation: showtemplateviewer2 .4s ease-in-out forwards;
    
    }
    @keyframes showtemplateviewer {
         from{
        
         opacity: 0;
         }
         to{
       
          opacity: 1;
         } 
    }
    @keyframes showtemplateviewer2 {
        from{
        transform: translateY(-20%);
      
        }
        to{
         transform: translateY(0%);
        
        } 
    }
    .temprow{
        font-size: 1.2vw;
        font-weight: bold;
    }
    .templateshowicon{
        height: 2.5vw;
        width: 2.5vw;
    }
    .deletetempicon{
      height: 3vw;
      width: 3vw;
      margin-left: 10%;
    
    }
    .edittempicon{
        height: 2.3vw;
        width: 2.3vw;
        margin-left: 10%;
    }
    .tempmetainfo{
        padding: 5%;
        font-size: 1.4vw;
        color:white
    }
    .previewbox{
        margin-top: 2%;
        padding: 2%;
        width: 97%;
        height: auto;
        border-radius: .4vw;
        background-color: white;
    
    }
    .compheader{
        font-size: 1.5vw;
        font-weight: bolder;
        color: black;
    }
    .compmessage{
        font-size: 1.5vw;
        color: black;
    }
    .tempbutton{
        width: 101.5%;
        height: 3vw;
        margin-top: 2%;
        display: flex;
        text-align: center;
        align-items: center;
        justify-content: center;
        font-size: 1.5vw;
        border-radius: .3vw;
        color:#16AEE8;
        background-color: white;
        border-top: 1px solid rgb(198, 198, 198);
    }
    .notemplates{
        width: 70%;
        height: 4vw;
        font-size: 1.6vw;
        font-weight: bold;
        display: flex;
        text-align: center;
        align-items: center;
        justify-content: center;
        padding: 1%;
        border-radius: .7vw;
        color: white;
        margin-top: 20%;
        margin-left: 15%;
        background-color: #387189;
    }
}