.authname{
 margin-top: 2%;   
}
.loginform{
    margin-top: 0%;
}
.lgin{
    margin-top: 4%;
}
.eloginhead{
    margin-top: -4%;
    margin-left: 0%;
}

.ebox2{
    display: flex;
    text-align: center;
    align-items: center;
    justify-content: center;
    margin-top: -3%;
    position: absolute;
    width: 18%;
    height: 2vw;
    border: 1px solid red;
    color: red;
    padding: 1%;
    margin-left: 19%;
    border-radius: .7vw;
}
.employeeauthinput{
    all: unset;
    /* background-color: #063142; */
    background-color: transparent;
    margin-top: 2%;
    border: 1px solid white;
    padding: 2%;
    width: 20vw;
    color: white;
    border-radius: .4vw;
}
.loginadmin{
    margin-top: 2%;
    width: 22.1vw;
    margin-left: 0%;
}
.loginforemployee{
    margin-top: 2%;
    width: 22.1vw;
    margin-left: 0%;

}
.loginforemployee:hover{
    background-color: #16AEE8;
    color: white;
    border-radius: .7vw;
}
.employeeauthinput::placeholder{
    color: white;
}
.eb2{
    display: flex;
    text-align: center;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    background-color: #0b242c;
    scale: .5;
    margin-left: 33%;
    margin-top: 27%;
}
.eb2:hover{
    transform: scale(.8);
    transition-duration: .5s;
}
.loginwithgoogle{
    position: absolute;
    counter-reset: white;
    font-size: 1.2vw;
    color: white;
    margin-top: 5%;
    margin-left: 5%;
    width: 20%;
}
.or{
    counter-reset: white;
    font-size: 1.2vw;
    color: white;
    margin-top: -4.5%;

position: absolute;
    margin-left: 19%;
}
.googlecontainer{
    display: flex;
}
.googlelogin{
    position: absolute;
    display: flex;
    text-align: center;
    align-items: center;
    justify-content: center;
    width: 40%;
    height: 4vw;
    background-color: white;
    border-radius: .4vw;
    margin-top: -1%;
color: #063142;
font-size: 1.5vw;
    cursor: pointer;
}
.googleicon{
    position: absolute;
    width: 4vw;
    height: 4vw;
    margin-left: -80%;
}
.googlelogin:hover{


}
.adminlogin{
    cursor: pointer;
    width: 10vw;
    height: 4vw;
    font-size: 1.5vw;
    color: #063142;
 font-family: "Nunito sans", sans-serif;
    background-color: white;
    margin-top: -1%;
    display: flex;
    text-align: center;
    align-items: center;
    justify-content: center;
    border-radius: .4vw;
    margin-left: 17%;
}
.forgetpassword{
    color: white;
    cursor: pointer;
    font-size: 1.1vw;
}
.forgetpassword:hover{
    color: #16AEE8;
    transform: translateX(5px);
    transition-duration: .2s;
}
.forgeterror{
    position: absolute;
    margin-left: 0%;
   margin-left: 34%;

}
@media screen and (max-width:412px) {
    .loginform{
        margin-top: 15%;
    }
    .eloginhead{
        margin-top: -4%;
        margin-left: 0%;
    }
    
    .ebox2{
        display: flex;
        text-align: center;
        align-items: center;
        justify-content: center;
      
        position: absolute;
        width: 30%;
        height: 4vw;
        border: 1px solid red;
        color: red;
        padding: 1%;
        margin-left: 40%;
        margin-top: 1%;
        border-radius: .7vw;
        font-size:3vw;
    }
    .employeeauthinput{
        all: unset;
        background-color:transparent;
        /* background-color: #063142; */
        margin-top: 2%;
        border: 1px solid #063142;
        padding: 2%;
        width: 93%;
        color: white;
        border-radius: 1vw;
    }
    .loginadmin{
        margin-top: 2%;
        width: 22.1vw;
        margin-left: 0%;
    }
    .loginforemployee{
        margin-top: 2%;
        width: 22.1vw;
        margin-left: 0%;
    
    }
    .loginforemployee:hover{
        background-color: #16AEE8;
        color: white;
        border-radius: .7vw;
    }
    .employeeauthinput::placeholder{
        color: white;
    }
    .eb2{
        display: flex;
        text-align: center;
        align-items: center;
        justify-content: center;
        cursor: pointer;
        background-color: #0b242c;
        scale: .5;
        margin-left: 33%;
        margin-top: 27%;
    }
    .eb2:hover{
        transform: scale(.8);
        transition-duration: .5s;
    }
    .loginwithgoogle{
        position: absolute;
        counter-reset: white;
        font-size: 1.2vw;
        color: white;
        margin-top: 5%;
        margin-left: 5%;
        width: 20%;

    }
    .or{
        display: none;
        position: absolute;
        color: white;
        font-size: 3vw;
        color: white;
        margin-left: 20%;
        margin-top: -4%;
    
    position: absolute;
        margin-left: 19%;
    }
    .googlecontainer{
        display: flex;
    }
    .googlelogin{
        position: absolute;
        display: flex;
        text-align: center;
        align-items: center;
        justify-content: center;
        width: 90%;
        height: 7vw;
        background-color: white;
        border-radius: 1vw;
        margin-top: -2%;
    color: #063142;
    font-size: 4vw;
    font-weight: bolder;
    cursor: pointer;
    }
    .googleicon{
        position: absolute;
        width: 7vw;
        height: 7vw;
        margin-left: -80%;
    }
    .googlelogin:hover{
    
    
    }
    .adminlogin{
        cursor: pointer;
        width: 10vw;
        height: 4vw;
        font-size: 1.5vw;
        color: #063142;
     font-family: "Nunito sans", sans-serif;
        background-color: white;
        margin-top: -1%;
        display: flex;
        text-align: center;
        align-items: center;
        justify-content: center;
        border-radius: .4vw;
        margin-left: 17%;
    }
    .forgetpassword{
        color: white;
        cursor: pointer;
        font-size: 3vw;
    }
    .forgetpassword:hover{
        color: #16AEE8;
        transform: translateX(5px);
        transition-duration: .2s;
    }
    .forgeterror{
        position: absolute;
        margin-left: 0%;
       margin-left: 34%;
    
    }
    
}