.trainingcontainer{
    width: 70%;
    height: 80vh;
    margin-top: 10vh;
    border-radius: 1vw;
    background-color: #081b23;
    color: white;
    margin-left: 23.5%;
    position: absolute;
    padding: 2%;
    box-sizing: border-box;
    font-size: 1.3vw;
    
}
.trainingurlinput{
    background-color: transparent;
    border: 1px solid white;
    border-radius: .7vw;
    padding: 1%;
    width: 50%;
    margin-top: 5%;

}
.trainingurlinput::placeholder{
    color: white;

}
.scrapwebsitebtn{
    background-color: #16AEE8;
    border-radius: .7vw;
    color: white;
    padding: 1%;
    width: 45%;
    margin-left: 2%;

}
.traininghead{
    margin-top: 2%;
}
.fileinputfortraining{
    all: unset;
    display: none;
}

#fileLabel{
    border: 2px dashed #16AEE8;
    padding: 2%;
    width: 94.5%;
    height: 100%;
    display: flex;
    align-items: center;
    border-radius: 1vw;
    color: #16AEE8;
    text-align: center;
    justify-content: center;
    align-items: center;
}

.fileInputBox{
    height: 30vh;
    display: flex;
    margin-top: 3%;
    width: 53%;
    flex-direction: column;
}

.fileinputbtn{
    width: 100%;
    border-radius: 0.7vw;
    background-color: #16AEE8;
    padding: 1%;
    margin-top: 2%;
}

.file-main{
    width: 100%;
    display: flex;
}

.training-status{
    color: #0f0;
    padding: 3%;
    text-align: center;
    width: 45%;
    display: flex;
    align-items: center;
    justify-content: center;
    border: 2px dashed #0f0;
    margin: 1%;
    box-sizing: border-box;
    border-radius: 0.7vw;
    margin-top: 3%;
    margin-left: 2%;

}

@media screen and (max-width:412px) {
    .trainingcontainer{
        width: 95%;
        height: 84vh;
        margin-top: 15vh;
        border-radius: 1vw;
        background-color: #081b23;
        color: white;
        margin-left: 2.5%;
        position: absolute;
        padding: 2%;
        box-sizing: border-box;
        font-size: 4vw;
        
    }
    .trainingurlinput{
        background-color: transparent;
        border: 1px solid white;
        border-radius: .7vw;
        padding: 1%;
        width: 50%;
        margin-top: 5%;
    
    }
    .trainingurlinput::placeholder{
        color: white;
    
    }
    .scrapwebsitebtn{
        background-color: #16AEE8;
        border-radius: .7vw;
        color: white;
        padding: 1%;
        width: 45%;
        margin-left: 2%;
    
    }
    .traininghead{
        margin-top: 2%;
        font-size: 4vw;
        margin-top: 4vh;
    }
    .fileinputfortraining{
        all: unset;
        display: none;
    }
    
    #fileLabel{
        border: 2px dashed #16AEE8;
        padding: 2%;
        width: 94.5%;
        height: 100%;
        display: flex;
        align-items: center;
        border-radius: 1vw;
        color: #16AEE8;
        text-align: center;
        justify-content: center;
        align-items: center;
    }
    
    .fileInputBox{
        height: 30vh;
        display: flex;
        margin-top: 3%;
        width: 53%;
        flex-direction: column;
    }
    
    .fileinputbtn{
        width: 100%;
        border-radius: 0.7vw;
        background-color: #16AEE8;
        padding: 1%;
        margin-top: 2%;
    }
    
    .file-main{
        width: 100%;
        display: flex;
        margin-top: 3vh;
    }
    
    .training-status{
        color: #0f0;
        padding: 3%;
        text-align: center;
        width: 45%;
        display: flex;
        align-items: center;
        justify-content: center;
        border: 2px dashed #0f0;
        margin: 1%;
        box-sizing: border-box;
        border-radius: 0.7vw;
        margin-top: 3%;
        margin-left: 2%;
    
    }
    
}