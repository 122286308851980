.homeinfosection{
width: 100%;
height: 300vh;
background-color: transparent;
position: absolute;
margin-top: 100vh;
}
.ourservices{
    font-size: 4vw;
    color: #16AEE8;
    display: flex;
    text-align: center;
    align-items: center;
    justify-content: center;
    margin-top: 8%;
}
.whatsappcrmservicehead{
    color: #16AEE8;
    font-size: 2.3vw;
    font-family: sans-serif;
    margin-left: 5%;
    margin-top: 8%;
    display: flex;
}
.whatsappcrmservice{
    color: #16AEE8;
    font-size: 2.3vw;
    font-family: sans-serif;
    margin-left: 5%;
    margin-top: 2%;
    display: flex;
}
.crminfo{
    color: white;
    font-size: 1.3vw;
    width: 50%;
}
.moreinfobtn{
    display: flex;
    background-color: #16AEE8;
    color: #04171e;
    border-radius:.4vw ;
    padding: 1%;
    width: 50%;
    font-size: 1.3vw;
    text-align: center;
    align-items: center;
    justify-content: center;
    margin-top: 3%;
    cursor: pointer;

}
.crmimage{
    width: auto;
    height: auto;
    max-width: 100%;
    max-height: 100vh;
    border-radius: 1vw;
    margin-top: -10%;
    margin-left: 0%;
    /* box-shadow: 2px 2px 12px -3px rgb(13, 39, 53); */
    /* animation: bounceimage 4s infinite linear forwards; */
}

@keyframes bounceimage {
    0%{
      transform: translateY(0px);
    }
    25%{
        transform: translateY(-5px);
    }
    50%{
        transform: translateY(-10px);
    }
    75%{
        transform: translateY(-5px);
    }
    100%{
        transform: translateY(0px);
    }
}
.chatbotservicehead{
    color: #16AEE8;
    font-size: 2.3vw;
    font-family: sans-serif;
    margin-left: 0%;
    margin-top: 8%;
    display: flex;
}
.chatbotservice{
display: flex;
margin-top: 15%;
}
.chatbotimage{
   width: 50%;
   height: 40vh; 
}
.chatbotinfo{
    width: 45%;
    color: white;
    font-size: 1.3vw;
}
.chatbotserviceimage{
    width: auto;
    height: auto;
    max-width: 100%;
    max-height:100vh;
    margin-left: 00%;
    margin-top: -13vh;
    border-radius: 1vw;
    /* border: 1.5px solid white; */
    /* animation: bounceimage 4s infinite linear forwards; */
}
.moreinfoicon{
    height: 2vw;
    width: 2vw;
}
.moreinfobtn:hover{
    .moreinfoicon{
        height: 2vw;
        width: 2vw;
        transform: translateX(5px);
        transition-duration: .3s;
    }
}
.morebtnchatbot{
    background-color: transparent;
    border: 1.3px solid #16AEE8;
    color: #16AEE8;
}
.seoanalysis{
    margin-left: 6%;
    margin-top: 10%;
}
.seoimage{
    margin-left: 6%;
    height: 100vh;
}
.divider{
    margin-top: 6%;
    width: 50%;
    height: 1px;
    border-radius: 1vw;
    color: #16AEE8;
    margin-left: 25%;
    background-color: #16AEE8;
}
.chatbotdivider{
    margin-top: 20%;
    width: 50%;
    height: 1px;
    border-radius: 1vw;
    color: #16AEE8;
    margin-left: 25%;
    background-color: #16AEE8;
}

.getstartedbtn{
    width: 60%;
    height: 6vh;
    padding: 2%;
    border: 2px solid #16AEE8;
    border-radius: .7vw;
    display: flex;
    text-align: center;
    align-items: center;
    justify-content: center;
    color: #16AEE8;
    font-size: 3vw;
    margin-top: 10vh;
    margin-left: 18%;
    cursor: pointer;
}
.getstartedbtn:hover{
    .getstartedicon{
        transform: translateX(30px);
        transition-duration: .5s;
    }
}

.getstartedicon{
    height: 4vw;
    width: 4vw;
    margin-left: 5%;
    margin-top: 1%;
}

@media  screen and (max-width:412px){

    .homeinfosection{
        width: 100%;
        height: 300vh;
        background-color: transparent;
        position: absolute;
        margin-top: 100vh;
        }
        .ourservices{
            font-size: 7vw;
            color: #16AEE8;
            display: flex;
            text-align: center;
            align-items: center;
            justify-content: center;
            margin-top: 8%;
        }
        .whatsappcrmservicehead{
            color: #16AEE8;
            font-size: 5vw;
            font-family: sans-serif;
            margin-left: 5%;
            margin-top: 8%;
            display: flex;
        }
        .whatsappcrmservice{
            color: #16AEE8;
            font-size: 2.3vw;
            font-family: sans-serif;
            margin-left: 5%;
            margin-top: 2%;
            display: flex;
        }
        .crminfo{
            color: white;
            font-size: 1.3vw;
            width: 50%;
        }
        .moreinfobtn{
            display: flex;
            background-color: #16AEE8;
            color: #04171e;
            border-radius:.4vw ;
            padding: 1%;
            width: 60%;
            height: 3vh;
            font-size: 3vw;
            text-align: center;
            align-items: center;
            justify-content: center;
            margin-top: 3%;
            cursor: pointer;
            border-radius: 2vw;
        
        }
        .crmimage{
            width: auto;
            height: auto;
            max-width: 100%;
            max-height: 100vh;
            border-radius: 1vw;
            margin-top: 0%;
            margin-left: -5%;
            /* box-shadow: 2px 2px 12px -3px rgb(13, 39, 53); */
            /* animation: bounceimage 4s infinite linear forwards; */
        }
        
        @keyframes bounceimage {
            0%{
              transform: translateY(0px);
            }
            25%{
                transform: translateY(-5px);
            }
            50%{
                transform: translateY(-10px);
            }
            75%{
                transform: translateY(-5px);
            }
            100%{
                transform: translateY(0px);
            }
        }
        .chatbotservicehead{
            color: #16AEE8;
            font-size: 6vw;
            font-family: sans-serif;
            margin-left: 0%;
            margin-top: 8%;
            display: flex;
        }
        .chatbotservice{
        display: block;
        margin-top: 15%;
        }
        .chatbotimage{
           width: 98%;
           height: 40vh; 

        }
        .chatbotinfo{
            width: 90%;
            color: white;
            font-size: 4vw;
            margin-left: 0%;
        }
        .mainchatbotinfo{
         margin-left: 6%;
        }
        .chatbotserviceimage{
            width: auto;
            height: auto;
            max-width: 100%;
            max-height:100vh;
            margin-left: -2%;
            margin-top: 0vh;
            border-radius: 1vw;
            /* border: 1.5px solid white; */
            /* animation: bounceimage 4s infinite linear forwards; */
        }
        .moreinfoicon{
            height: 4vw;
            width: 4vw;
        }
        .moreinfobtn:hover{
            .moreinfoicon{
                transform: translateX(5px);
                transition-duration: .3s;
            }
        }
        .morebtnchatbot{
            background-color: transparent;
            border: 1.3px solid #16AEE8;
            color: #16AEE8;
        }
        .seoanalysis{
            margin-left: 6%;
            margin-top: 10%;
        }
        .seoimage{
            margin-left: 0%;
            margin-top: 10%;
        }
        .divider{
            margin-top: 30%;
            width: 50%;
            height: 1px;
            border-radius: 1vw;
            color: #16AEE8;
            margin-left: 25%;
            background-color: #16AEE8;
        }
        .chatbotdivider{
            margin-top: 30%;
            width: 50%;
            height: 2px;
            border-radius: 1vw;
            color: #16AEE8;
            margin-left: 25%;
            background-color: #16AEE8;
        }
        
        .getstartedbtn{
            width: 60%;
            height: 6vh;
            padding: 2%;
            border: 2px solid #16AEE8;
            border-radius:2vw;
            display: flex;
            text-align: center;
            align-items: center;
            justify-content: center;
            color: #16AEE8;
            font-size: 3vw;
            margin-top: 10vh;
            margin-left: 18%;
            cursor: pointer;
        }
        .getstartedbtn:hover{
            .getstartedicon{
                transform: translateX(10px);
                transition-duration: .5s;
            }
        }
        
        .getstartedicon{
            height: 4vw;
            width: 4vw;
            margin-left: 5%;
            margin-top: 1%;
        }
          
    
}
