
.privacycontainer{
    position: absolute;
    margin-top: 10%;
}
.Pmainhead{
    font-size: 4vw;
}
.termscontainer{
    position: absolute;
    margin-top: 10%;
}