.viewuserscontainer{
    position: absolute;
    width: 70%;
    height: 9vh;
    padding: 1%;
    border-radius: .7vw;
    margin-top: 5%;
    margin-left: 24%;
    background-color: #054864;
    box-shadow: 2px 2px 12px -3px black ; 
    animation: viewheadslidein  .4s   ease-in-out forwards;
}
@keyframes viewheadslidein {
    from{
       opacity: 0;
    }
    to{
   opacity: 1;
    }
    
}
.viewhead{
    color: white;
    font-size: 2.3vw;
    font-weight: bolder;
}
.users{
    margin-left: -3%;
}
.assignbtn{
 width: 40%;
 height: 3vw;
 font-size: 1.2vw;
 color: white;
 text-align: center;
 align-items: center;
 justify-content: center;
 border-radius: .4vw;

}
.removebtn{
    width: 95%;
    height: 3vw;
    font-size: 1.2vw;
    color: white;
    text-align: center;
    align-items: center;
    justify-content: center;
    border-radius: .4vw;
    margin-left: 4%;
}
@media screen and (max-width:412px){
    .viewuserscontainer{
        position: absolute;
        width: 95%;
        height: auto;
        padding: 1%;
        border-radius: .7vw;
        margin-top: 30%;
        margin-left: 1.5%;
        background-color: #054864;
        box-shadow: 2px 2px 12px -3px black ; 
        animation: viewheadslidein  .4s   ease-in-out forwards;
        background-color:white;
    }
    @keyframes viewheadslidein {
        from{
           opacity: 0;
        }
        to{
       opacity: 1;
        }
        
    }
    .viewhead{
        color: white;
        font-size: 2.3vw;
        font-weight: bolder;
    }
    .users{
        margin-left: -3%;
    }
    .assignbtn{
     width: 40%;
     height: 3vw;
     font-size: 1.2vw;
     color: white;
     text-align: center;
     align-items: center;
     justify-content: center;
     border-radius: .4vw;
    
    }
    .removebtn{
        width: 95%;
        height: 6vw;
        font-size: 2vw;
        color: white;
        text-align: center;
        align-items: center;
        justify-content: center;
        border-radius: .4vw;
        margin-left: 4%;
    }
}