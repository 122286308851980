.mainlogincontainer{
    display: flex;
}
.loginhead{
    font-size: 2.5vw;
    color: white;
    margin-left: 5%;
    position: absolute;
    margin-top: 5%;

}
.logincontainer{
    /* border: 1px solid white; */
    padding: 3%;
    z-index: 0;
    position: absolute;
    background-color: transparent;
    /* background-color: #053042; */
    font-weight: bolder;
    width: 50%;
    margin: 2%;
    height: 20vw;
    box-shadow: 2px 2px 12px 4px black;
    border-radius: .7vw;
    margin-top: 12%;
    margin-left: 18%;

}
.facebookicon{
    width: 4vw;
    height: 4vw;
    margin-left: -92%;
    background-color: white;
    border-right: 1px solid #032636;
}
.signupbtn{
    margin-left: -50%;
    margin-right: 5%;
}
.loginbtn{
    overflow: hidden;
    width: 45%;
    height: 4vw;
    /* background-color: #032636; */
    background-color: transparent;
    backdrop-filter: blur(10px);
    border-radius: .7vw;
    font-size: 1.3vw;
    display: flex;
    text-align: center;
    align-items: center;
    justify-content: center;
    font-weight: bolder;
    border: 1px solid white;
    margin-top: 2%;
    margin-left: 0%;
    margin-bottom: 5%;
}
.loginbtn:hover{
   background-color: white;
   color: #032636;
   transition-duration: .2s;

}
.logintextinfo{
    position: absolute;
    margin-left: 7%;
}
.loginstyle{
    display: flex;
    width: 100%;
    height: 100vh;
    background-color: black;
   
  
}
.loginimage{
    margin-top: 8%;
    margin-left: 40%;
    width: 55vw;
    height: 35vw;
    z-index: 100000;
    /* animation: werve 8s infinite ease-in-out forwards; */
}
@keyframes werve {
   0%{
    transform: translateY(0vw);
   }
   50%{
     transform: translateY(-5vw);
   }
   100%{
    transform: translateY(0vw);
   }
    
}
.stylebubble{
    position: absolute;
    width: 10vw;
    height: 10vw;
    border-radius: 50%;
    background-color: #3f90b1;
    /* animation: werve 8s infinite ease-in-out forwards; */

}

.signupbth{
    width: auto;
    z-index: 3000;
    color: white;
    cursor: pointer;
}

.ebox2{
    position: absolute;
    color: red;
    font-size: 1.1vw;
    width: 20%;
    height: 3vw;
    border: 1px solid red;
    border-radius: .4vw;
    display: flex;
    text-align: center;
    align-items: center;
    justify-content: center;
    margin-top: -3%;
    margin-left: 27%;
}

@media screen and (max-width:412px) {
  
    .mainlogincontainer{
        display: flex;
    
    }
    .loginhead{
        font-size: 8vw;
        color: white;
        margin-left: 5%;
        position: absolute;
        margin-top: 5%;
    }

    .logincontainer{
        border:0px;
        padding: 3%;
        z-index: 0;
        position: absolute;
        background-color:transparent;
        backdrop-filter: blur(8px);
        box-shadow: 2px 2px 12px -2px black;
        font-weight: bolder;
        width: 70%;
        margin: 2%;
        height: 60vw; 
        box-shadow: none;
        border-radius:2vw;
        margin-top: 55%;
        margin-left: 12%;
    }

    .facebookicon{
        width: 4vw;
        height: 4vw;
        margin-left: -92%;
        background-color: white;
        border-right: 1px solid #032636;
    }
    .loginbtn{
        overflow: hidden;
        width: 97%;
        padding: 1%;
        height: 8vw;
        background-color:transparent;
        border-radius: 1vw;
        font-size: 4vw;
        display: flex;
        text-align: center;
        align-items: center;
        justify-content: center;
        font-weight: bolder;
        border: 1px solid white;
        margin-top: 5%;
        margin-left: 0%;
        margin-bottom: 5%;
    }
    .loginbtn:hover{
       background-color: white;
       color: #032636;
       transition-duration: .2s;
    
    }
    .logintextinfo{
        position: absolute;
        margin-left: 7%;
    }
    .loginstyle{
        display: flex;
        width: 100%;
        height: 100vh;
        background-color: #032636;
    }
    .loginimage{
        display: none;
        margin-top: 6%;
        margin-left: 40%;
        width: 55vw;
        height: 35vw;
        z-index: 1000;
        /* animation: werve 8s infinite ease-in-out forwards; */
    }
    @keyframes werve {
       0%{
        transform: translateY(0vw);
       }
       50%{
         transform: translateY(-5vw);
       }
       100%{
        transform: translateY(0vw);
       }
        
    }
    .stylebubble{
        position: absolute;
        width: 10vw;
        height: 10vw;
        border-radius: 50%;
        background-color: #3f90b1;
        /* animation: werve 8s infinite ease-in-out forwards; */
    
    }
    
    .signupbth{
        z-index: 3000;
        color: white;
        cursor: pointer;
    }
    
    .ebox2{
        position: absolute;
        color: red;
        font-size: 1.1vw;
        width: 20%;
        height: 3vw;
        border: 1px solid red;
        border-radius: .4vw;
        display: flex;
        text-align: center;
        align-items: center;
        justify-content: center;
        margin-top: -3%;
        margin-left: 27%;
    }
    .employeeauthinput{
        all: unset;
        background-color: #063142;
        margin-top: 2%;
        border: 1px solid white;
        padding: 2%;
        width: 90%;
        color: white;
        border-radius: .4vw;
    }
   

    
}