.dashboardcontainer{
    display: flex;
    margin-left: 30%;
    width: 70%;
    animation: dashshow .4s;
}
@keyframes dashshow {
    from{
    opacity: 0;
    }
    to{
        opacity: 2;
    }
    
}
.newuserhead{
    font-weight: bolder;
    display: flex;
    text-align: center;
    align-items: center;
    justify-content: center;
   border-radius: .4vw;
    width: 100%;
    height: 3vw;
    font-size: 1.5vw;
    color: white;
    background-color: #16AEE8;
padding: 3%;
margin-left: -2.7%;
    font-family: "Nunito sans",sans-serif;
}
.linechart{
    width: 40%;
    margin: 2%;
    /* box-shadow: 2px 2px 12px -3px black; */
    padding: 3%;
    border-radius: 2vw;
    background: white;
    box-shadow:  20px 20px 60px #bebebe,
                 -20px -20px 60px #ffffff;
}
.piechart{
    position: absolute;
    margin-top: 2%;
    margin-left: -3%;
}
.analysis{
    position: absolute;
    width: 60%;
    height: 30vw;
    margin-left: 35%;
    margin-top: 1%;
    border-radius: 2vw;
    background: white;
    box-shadow:  20px 20px 60px #bebebe,
                 -20px -20px 60px #ffffff;

}
.analyaishead{
    font-size: 3vw;
    padding: 1%;
    display: flex;
    text-align: center;
    align-items: center;
    justify-content: center;
}