.home{
  position: absolute;
}
.homebackground{
  width: 100%;
  background-color: #010b0e;
  height: 750vh;
  min-width: 100vh;
  max-width: auto;
  position: absolute;
  z-index: -1;
}
.zopinsname{
  color: #16AEE8;
  font-size: 6.5vw;
}
.zopinshead{
  position: absolute;
  font-size: 8vw;
  width: 95%;
  margin-top: 40vh;
  margin-left: 4%;
  color: #16AEE8;
  font-family: 'Lucida Sans', 'Lucida Sans Regular', 'Lucida Grande', 'Lucida Sans Unicode', Geneva, Verdana, sans-serif;

}
.zopinshead2{
  position: absolute;
  font-size: 3vw;
width: 100%;
margin-top: -4.5%;
  color: white;
  font-family: 'Lucida Sans', 'Lucida Sans Regular', 'Lucida Grande', 'Lucida Sans Unicode', Geneva, Verdana, sans-serif;
}
.zopinshead3{
  font-size: 2vw;
  margin-top: 6%;
width: 100%;
  color: white;
  font-family: 'Lucida Sans', 'Lucida Sans Regular', 'Lucida Grande', 'Lucida Sans Unicode', Geneva, Verdana, sans-serif;
}
.forwardicon{
  height: 1.5vw;
  width: 1.5vw;
}
.zopinssubhead3{
  font-size: 1.2vw;
  margin-top: 0.5%;
width: 100%;
  color: white;
  font-family: 'Lucida Sans', 'Lucida Sans Regular', 'Lucida Grande', 'Lucida Sans Unicode', Geneva, Verdana, sans-serif;
}
.btnsboxes{
  display: flex;
  margin-top: 1%;
}
.livedemobtn{
  color: black;
  background-color: #16AEE8;
  margin-right: 1%;
}
.joinfreetrialbtn{
  font-size: 1.4vw;
  border: 1px solid #16AEE8;
  display: flex;
  text-align: center;
  align-items: center;
  justify-content: center;
  width: 20%;
  height: 7vh;
  cursor: pointer;
  border-radius: .4vw;
}



@media only screen and (max-width: 420px){
    .home{
      position: absolute;
      margin-top: 30%;
      width: 50%;
  
    }
    .homebackground{
      width: 100%;
      height: 620vh;
      min-width: 100%;
      max-width: auto;
      position: absolute;
      z-index: -1;
      margin-top: -110vh;
    }
    .zopinsname{
      color: #16AEE8;
      font-size: 20vw;
      font-weight: bolder;
    }
    .zopinshead{
      display: block;
      position: absolute;
      font-size: 8vw;
      width: 95%;
      margin-top: 40vh;
      margin-left: 4%;
      color: #16AEE8;
      font-family: 'Lucida Sans', 'Lucida Sans Regular', 'Lucida Grande', 'Lucida Sans Unicode', Geneva, Verdana, sans-serif;
    
    }
    .extracontent{
      display: inline-block;
      font-size: 3w;
      width: 100%;
      color: #16AEE8;
      margin-top: -2vh;
    }
    .zopinshead2{
      position: absolute;
      font-size: 5vw;
    width: 100%;
    margin-top: -4.5%;
      color: white;
      font-family: 'Lucida Sans', 'Lucida Sans Regular', 'Lucida Grande', 'Lucida Sans Unicode', Geneva, Verdana, sans-serif;
    }
    .zopinshead3{
      font-size: 4vw;
      margin-top: 20vh;
      width: 100%;
      color: white;
      font-family: 'Lucida Sans', 'Lucida Sans Regular', 'Lucida Grande', 'Lucida Sans Unicode', Geneva, Verdana, sans-serif;
    }
    .forwardicon{
      height: 5vw;
      width: 5vw;
    }
    .zopinssubhead3{
      text-align: start;
      align-items: start;
      justify-content: start;
      font-size: 3vw;
    width: 100%;
      color: white;
      font-family: 'Lucida Sans', 'Lucida Sans Regular', 'Lucida Grande', 'Lucida Sans Unicode', Geneva, Verdana, sans-serif;
    }
    .btnsboxes{
      display: flex;
      margin-top: 3vh;
      width: 100%;
    }
    .livedemobtn{
      color: black;
      background-color: #16AEE8;
      margin-right: 1%;
    }
    .joinfreetrialbtn{
      font-size: 4vw;
      border: 1px solid #16AEE8;
      display: flex;
      text-align: center;
      align-items: center;
      justify-content: center;
      width: auto;
      padding: 3%;
      height: 5vh;
      cursor: pointer;
      border-radius: 2vw;
    }
    
  
}
