
.main-search-container{
    display: flex;
    position: absolute;
    width: 25%;
    margin-left: 5%;
    height: 7vw;
    background-color: #032533;
    border-bottom: 1.5px solid white;
    z-index: 20;
}

.search-container{
    width: 92%;
    position: absolute;
    border-radius: .4vw;
    margin: 2%;
    padding: 2%;
    margin-top: 13%;
}
.search{
    all: unset;
    display: flex;
    position: absolute;
    width:76.5%;
    font-size: 1.5vw;
    height: 2.5vw;
    color: #031218;
    margin-top: -1.8%;
    margin-left: -1.2%;
    background-color: white;
    padding:1.5%;
    border-radius: 5vw;
    padding-left: 20%;

}
.search::placeholder{
    color: #031218;
    font-size: 1.5vw;
}
.searchicon{
    position: absolute;
    height: 2vw;
    width: 2vw;
    margin-left: 1%;
    margin-top: -1%;
    padding: 2%;

    border-radius: .4vw;

}
.maincontactname{
    display: flex;
    align-items: center;
    margin: 4%;
    height: auto;
    cursor: pointer;
    border-bottom: 1px solid #042836;

}
.contacticon{
  
  font-size: 2vw;
display: flex;
  width: 3vw;
  height: 3vw;
  border-radius: 50%;
  color:#074158 ;
  background-color: white;
  font-weight: bolder;
  border: 2px solid white;
  text-align: center;
  align-items: center;
  justify-content: center;
  margin:2.5%;
}
.contact{
    font-size:1.3vw;
    color: white;
    margin-left: 2%;
    margin-top: 5%;
    flex: 1;
}
.contactphone{
 
    display: flex;
    color: white;
    font-size: 1vw;
   
}
.newchatbtn{
    display: flex;
    position: absolute;
    height: 2.5vw;
    width: 2.5vw;
    cursor: pointer;
    margin-left: 87%;
}
.addnewchatbtn{
    display: flex;
    text-align: center;
    align-items: center;
    justify-content: center;
    padding: 1%;
    border-radius: .4vw;
    font-size: 1.5vw;
    color:white;
    font-weight: bold;
    background-color: #16AEE8;
     margin-top: 5%;
     cursor: pointer;
}
.addnewchatbtn:hover{
    background-color: #031218;
    color: white;
    transition-duration: .5s;
}
.newclientinputs{
    all: unset;
    width: 96%;
    height: 2vw;
    font-size: 1.2vw;
    padding: 2%;
    border-radius: .7vw;
    border: 1px solid black;
    color: black;
    margin-top: 2%;
}
.newclientinputs::placeholder{
    color: black;
    font-weight: bolder;
    font-size: 1.2vw;
}
.removechaticonbox{
    display: flex;
z-index: 0;
margin-left: auto;
cursor:pointer;
border-radius: 1vw;

}
.removechaticon{
    height: 2vw;
    width: 2vw;
    background-color: white;
    border-radius: 50%;
    padding: 2%;
    border: 1px solid red;
}

.employeeheadname{
    position: absolute;
    color: white;
    margin-left: 4%;
    font-size: 2vw;
    
}
@media screen and (max-width:996px) {
    .search-container{
        width: 79%;
        position: absolute;
        border-radius: .7vw;
        margin: 2%;
        padding: 2%;
        margin-top: 10%;
        background-color: #053e56;
    
    }
    .searchicon{
        position: absolute;
        height: 2.2vw;
        width: 2.2vw;
        margin-left: 15%;
        margin-top: -1.4%;
        padding: 2%;
    
        border-radius: .4vw;
        background-color: #054864;
    
    }
    
}
.hiddendiv{
    width: 100%;
    height: 16vh;
}
@media screen and (max-width:412px) {

  
    .chatsheadinfo{
        position:absolute;
        color:#16AEE8;
        font-size:7vw;
        margin:2%;
        margin-top:2%;
        font-weight:bolder
    }
    .hiddendiv{
        height: 4vh;
        width: 100%;
    }
    .searchicon{
        position: absolute;
        height: 2.2vw;
        width: 2.2vw;
        /* margin-left: 35%; */
        padding: 2%;
        border-radius: .4vw;
        background-color: #054864;
    }
    .main-search-container{
        display: flex;
        position: fixed;
        width: 100%;
        /* margin-left: 5%; */
        height: 30vw;
        background-color: #031218;
        border-bottom: 1.5px solid white;
        margin-left:0%;
    }
    
    .search-container{
        width: 92%;
        height:3vh;
        position: absolute;
        border-radius: 10vw;
        margin: 2%;
        padding: 2%;
        margin-top: 16%;
        background-color: white;
    
    }
    .search{
        all: unset;
        position: absolute;
        width:70%;
        font-size: 4vw;
        height: 6vw;
        color: #032533;
        margin-top: 0%;
        margin-left: 2%;
    }
    .search::placeholder{
        color: #032533;
        font-weight:bolder;
        font-size: 4vw;
    }
    .searchicon{
        position: absolute;
        height: 7vw;
        width: 7vw;
        margin-left: 81%;
        margin-top: -2.2%;
        padding: 2%;
        background-color: transparent;
        border-radius: .4vw;
    
    }
    .maincontactname{
        display: flex;
        margin: 4%;
        font-size: 4vw;
        height: auto;
        cursor: pointer;
        border-bottom: 1px solid #042836;
    
    }
    .contacticon{
      font-size: 6vw;
    display: flex;
      width: 11vw;
      height: 11vw;
      border-radius: 50%;
      color:#074158 ;
      background-color: white;
      font-weight: bolder;
      border: 2px solid white;
      text-align: center;
      align-items: center;
      justify-content: center;
      margin:2.5%
    }
    .contact{
        font-size:5vw;
        color: white;
        margin-left: 2%;
        margin-top: 6%;
    }
    .contactphone{
        display: flex;
        color: white;
        font-size: 1vw;
    }
    .newchatbtn{
        display: flex;
        text-align: center;
        align-items: center;
        justify-content: center;
        color: #032533;
        margin-top: 2%;
        font-weight: bolder;
        width: 30%;
        height: 3vh;
        font-size: 1.2vw;
        border: 1px solid white;
        padding: 1%;
        border-radius: .4vw;
        background-color: white;
        margin-left: 65%;
        cursor: pointer;
    }
    .newchathead{
        font-size: 2vw;
        color: #053e56;
        font-weight: bolder;
    
    }
    .newclientinputs{
        all: unset;
        width: 96%;
        height: 5vw;
        font-size: 3vw;
        padding: 3%;
        border-radius: 1vw;
        border: 1px solid black;
        color: black;
        margin-top: 5%;
    }
    .newclientinputs::placeholder{
        color: black;
        font-weight: bolder;
        font-size: 3vw;
    }
    .removechaticonbox{
        display: flex;
    z-index: 0;
    margin-left: auto;
    cursor:pointer;
    border-radius: 4vw;
    background-color: red;
    
    }
    .addnewchatbtn{
        display: flex;
        text-align: center;
        align-items: center;
        justify-content: center;
        padding: 1%;
        border-radius: 1vw;
        font-size: 3vw;
        color:white;
        font-weight: bold;
        width: 100%;
        height: 5vh;
        background-color: #16AEE8;
         margin-top: 5%;
         cursor: pointer;
    }
    
}