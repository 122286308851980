.maindashcontainer{
    z-index: 1;
    animation: showdash .5s ease-in-out forwards;
}
@keyframes showdash {
    from{
   opacity: 0;
   transform: translateX(-5vw);
    }
    to{
    opacity: 1;
    transform: translateX(0vw);
    }
    
}
.dashboardhead{
    position: fixed;
    position: absolute;
    z-index: -1;
    font-size: 2.6vw;
    color: #06435b;
    font-weight: bolder;
margin-left: 6%;

}
.dashnav{
    position: fixed;
     border-radius: 0px;
    margin-top: 0%;
    padding: 1%;
    margin-left: 5%;
    position: absolute;
    width: 10%;
    height: 95.5vh;
    /* background-color: #3c7083; */
}
.dele{
    cursor: pointer;
    font-weight: 800;
    margin-top: 10%;
    border-radius: .4vw;
    display: flex;
    text-align: center;
    align-items: center;
    justify-content: center;
  height: 4vw;
  width: 100%;
  border: 1px solid white;
  background-color: #16AEE8;
  background-color: transparent;
  color: white;
  box-shadow: 2px 2px 12px -3px black;
  box-shadow: none;
  font-size: 1.2vw;
}



.newtemplates{
    display: flex;
    margin-left: 20%;
    position: absolute;
    width: 80%;
    height: 100vh;
    animation: shownewtemp .5s ease-in-out forwards;
    
}
@keyframes shownewtemp {
    from{
   opacity: 0;
 
    }
    to{
    opacity: 1;

    }
    
}
.templategenerator{
    position: absolute;
    overflow: scroll;
    width: 65%;
    margin-left: 3%;
   
    height: 100vh;
}
.headergenerator{
  
    width: 300%;
    height: auto;
    animation: showheadergenerator .5s ease-in-out forwards;
    
}
@keyframes showheadergenerator {
    from{
     opacity: 0;
    }
    to{
      opacity: 1;
    }
    
}
.appactivebtn{
    width: auto;
    height: auto;
    background-color: greenyellow;
    border-radius: .7vw;
    padding: 1%;
    color: #06435b;
    font-weight: bolder;

}
@media screen and (max-width:412px){
    .maindashcontainer{
        /* position: fixed; */
        margin-left:0%;
        display:flex;
        z-index: 0;
        animation: showdash .5s ease-in-out forwards;
        top: 10vh;
    }
    @keyframes showdash {
        from{
       opacity: 0;
       transform: translateY(-5vw);
        }
        to{
        opacity: 1;
        transform: translateY(0vw);
        }
        
    }
    .dashboardhead{
        display:flex;
        position: fixed;
        z-index: -1;
        font-size: 2.6vw;
        color: #06435b;
      
        /* font-weight: bolder; */

    
    }
    .dashnav{
        position: fixed;
        overflow:scroll;
        margin-left:0%;
        display: grid;
  grid-template-columns: repeat(4, 1fr); /* 4 columns with equal width */
  grid-template-rows: repeat(2, auto); /* 2 rows with auto height */
  gap: 10px;
         border-radius: 0px;
        margin-top: 14%;
        position: absolute;
        width: 98%;
        height:7vh;
        /* background-color: #3c7083; */
        background-color: #030e12;
    }

    .dele{
        cursor: pointer;
        font-weight: lighter;
        margin-top: 10%;
        border-radius: 2vw;
        display: flex;
        text-align: center;
        align-items: center;
        justify-content: center;
      height: auto;
      width: 100%;
      /* background-color: #16AEE8; */
      background-color: transparent;
      color: white;
      /* box-shadow: 2px 2px 12px -3px black; */
      box-shadow: none;
      font-size: 3vw;
      margin:1%;
      padding:2%;
      border: 0px;
    }
    
    
    
    .newtemplates{
        display: flex;
        margin-left: 20%;
        position: absolute;
        width: 80%;
        height: 100vh;
        animation: shownewtemp .5s ease-in-out forwards;
        
    }
    @keyframes shownewtemp {
        from{
       opacity: 0;
     
        }
        to{
        opacity: 1;
    
        }
        
    }
    .templategenerator{
        position: absolute;
        overflow: scroll;
        width: 120%;
        margin-left: -22%;
        margin-top: 60%;
       
        height: 70vh;
    }
    .headergenerator{
      
        width: 300%;
        height: auto;
        animation: showheadergenerator .5s ease-in-out forwards;
        
    }
    @keyframes showheadergenerator {
        from{
         opacity: 0;
        }
        to{
          opacity: 1;
        }
        
    }
    .appactivebtn{
        width: auto;
        height: auto;
        background-color: greenyellow;
        border-radius: 1vw;
        padding: 1%;
        color: #06435b;
        font-weight: bold;
        border: 1px solid #030e12;
    
    }
}