.pathdeciderbox{
    display: flex;
    height: 100vh;
    width: 100%;
    background-color: #021117;
}

.path{
width: 30%;
height: 50vh;
border-radius: 1vw;
box-shadow: 2px 2px 12px -4px black;
margin-left: 2.5%;
margin-top: 10%;
text-align: center;
align-items: center;
justify-content: center;
cursor: pointer;
}

.path:hover{
    border: 1px solid #16AEE8;
    scale: 1.03;
    transition-duration: .3s;
}
.pathimage{
margin-top: 16%;
height: 4.5vw;
width: 4.5vw;
}
.pathimage2{
    margin-top: 20%;
}
.pathinfo{
    color: #16AEE8;
    font-size: 3vw;
}


@media screen and (max-width:412px) {
    .pathdeciderbox{
        position: fixed;
        display: block;
        height: 100vh;
        width: 100%;
        background-color: #021117;
    }
    
    .path{
    width: 90%;
    height: 25vh;
    border-radius: 3vw;
    box-shadow: 2px 2px 12px 1px black;
    margin-left: 5%;
    margin-top: 15%;
    text-align: center;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    }
    
    .path:hover{
        border: 1px solid #16AEE8;
        scale: 1.03;
        transition-duration: .3s;
    }
    .pathimage{
    margin-top: 16%;
    height: 15vw;
    width: 15vw;
    }
    .pathimage2{
        margin-top: 20%;
    }
    .pathinfo{
        color: #16AEE8;
        font-size: 6vw;
    }   
}