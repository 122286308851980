:root{
    --bg:#d6edf7;
    --input:#032923;
    --btn:#16aeea;
}

.key-container{
    width: 75%;
    display: flex;
    height: 95vh;
    /* background-color: #eee; */
    margin-left: 23%;
}
.edit-box{
    display: flex;
    flex-direction: column;
    width: 65%;
    padding: 2%;
    border-radius: 0.7vw;
    margin: 2%;
    background-color: var(--bg);
    font-size: 1.5vw;
    color: #032923;
}
.view-box{
    background-color: var(--input);
    width: 35%;
    margin: 2% 0;
    border-radius: 0.7vw;
    padding: 1%;
    overflow-y: scroll;
}
.keytraininghead{
    margin-bottom: 6%;
    font-size: 1.5vw;
}
.qa-box{
    background-color: var(--bg);
    padding: 0.5%;
    margin-bottom: 3%;
    border-radius: 0.3vw;
    color: #032923;
}
.ques,.ans{
    background-color: var(--input);
    color: white;
    margin: 0.3%;
    padding: 2%;
    border-radius: 0.2vw;
}

.edit-box textarea{
    margin-bottom: 5%;
    background-color: var(--input);
    color: white;
    padding: 1.3%;
    font-size: 20px;
    border-radius: 0.7vw;
    border: none;
    resize: none;
    &:focus{
        outline:none;
    }
}

.edit-box>button{
    background-color: var(--btn);
    padding: 1%;
    border-radius: 0.7vw;
    border: none;
    font-size: 25px;
    color: white;
    height: 9vh;
}
.keyinput{
    font-size: 1.2vw;
}
.downloadjsonlfilebtn{
    background-color: #16aeea;
    width: 40%;

    color: white;
    border-radius: .7vw;
    text-align: center;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    font-size: 1.2vw;
    margin-left: 60%;
}
.myquesansbtn{
    background-color: #16aeea;
    width: 40%;
    color: white;
    border-radius: .7vw;
    text-align: center;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    font-size: 1.2vw;
    margin-left: 18%;
    margin-top: -7.5%;
}
.newquesans{
    background-color: #16aeea;
    width: 15.5%;
    color: white;
    border-radius: .7vw;
    text-align: center;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    font-size: 1.2vw;
    margin-left: 0%;
    margin-top: -7.5%;
}
.downloadjsonlfilebtn:hover{
    background-color: #032923;
    transition-duration: .3s;
}
.myquesansbtn:hover{
    background-color: #032923;
    transition-duration: .3s;
}
.newquesans:hover{
    background-color: #032923;
    transition-duration: .3s;
}
.successmessage{
    margin-top: 35vh;
    margin-left: 1%;
    height: 10vh;
   background-color: white;
   color: #16aeea;
   padding: 1%;
   display: flex;
   text-align: center;
   align-items: center;
   justify-content: center; 
   font-size: 1.5vw;
   border-radius: .7vw;
   animation: showsuccess .2s ease-in-out forwards;
}
@keyframes showsuccess {
    from{
     scale: .7;
    }
    to{
    scale: 1;
    }
}

@media screen and (max-width:412px) {
    
    :root{
        --bg:#d6edf7;
        --input:#032923;
        --btn:#16aeea;
    }
    
    .key-container{
        width: 95%;
        display: flex;
        height: 85vh;
        /* background-color: #eee; */
        margin-left: 2.5%;
        margin-top: 15vh;
    }
    .edit-box{
        display: flex;
        flex-direction: column;
        width: 65%;
        padding: 2%;
        border-radius: 0.7vw;
        margin: 2%;
        background-color: var(--bg);
        font-size: 3vw;
        color: #032923;
    }
    .view-box{
        background-color: var(--input);
        width: 35%;
        margin: 2% 0;
        border-radius: 0.7vw;
        padding: 1%;
        overflow-y: scroll;
    }
    .keytraininghead{
        margin-bottom: 6%;
        font-size: 2.5vw;
    }
    .qa-box{
        background-color: var(--bg);
        padding: 0.5%;
        margin-bottom: 3%;
        border-radius: 0.3vw;
        color: #032923;
    }
    .ques,.ans{
        background-color: var(--input);
        color: white;
        margin: 0.3%;
        padding: 2%;
        border-radius: 0.2vw;
    }
    
    .edit-box textarea{
        margin-bottom: 5%;
        background-color: var(--input);
        color: white;
        padding: 1.3%;
        font-size: 20px;
        border-radius: 0.7vw;
        border: none;
        resize: none;
        font-size: 3vw;
        &:focus{
            outline:none;
        }
    }
    
    .edit-box>button{
        display: flex;
        text-align: center;
        align-items: center;
        justify-content: center;
        background-color: var(--btn);
        padding: 1.5%;
        border-radius: 0.7vw;
        border: none;
        font-size: 3vw;
        color: white;
        height: 3vh;
    }
    .keyinput{
        font-size: 1.2vw;
    }
    .downloadjsonlfilebtn{
        background-color: #0e698d;
        width: 40vw;
        height: 3vh;
    
        color: white;
        border-radius: .7vw;
        display: flex;
        text-align: center;
        align-items: center;
        justify-content: center;
        cursor: pointer;
        font-size: 3vw;
        margin-left: 30%;
        padding: 0;
    }
    .myquesansbtn{
        background-color: #16aeea;
        width: 40vw;
        height: 3vh;
        display: flex;
        color: white;
        border-radius: .7vw;
        text-align: center;
        align-items: center;
        justify-content: center;
        cursor: pointer;
        font-size: 3vw;
        margin-left: 30%;
        margin-top: 3%;
    }
    .newquesans{
        background-color: #16aeea;
        width: 15vw;
        height: 6.8vh;
        color: white;
        display: flex;
        border-radius: .7vw;
        text-align: center;
        align-items: center;
        justify-content: center;
        cursor: pointer;
        font-size: 3vw;
        margin-left: 0%;
        margin-top: -27%;
    }
    .downloadjsonlfilebtn:hover{
        background-color: #032923;
        transition-duration: .3s;
    }
    .myquesansbtn:hover{
        background-color: #032923;
        transition-duration: .3s;
    }
    .newquesans:hover{
        background-color: #032923;
        transition-duration: .3s;
    }
    .successmessage{
        margin-top: 35vh;
        margin-left: 1%;
        height: 10vh;
       background-color: white;
       color: #16aeea;
       padding: 1%;
       display: flex;
       text-align: center;
       align-items: center;
       justify-content: center; 
       font-size: 1.5vw;
       border-radius: .7vw;
       animation: showsuccess .2s ease-in-out forwards;
    }
    @keyframes showsuccess {
        from{
         scale: .7;
        }
        to{
        scale: 1;
        }
    }
}