table {
    border-collapse: collapse;
    width: 100%;
    
  }
  th, td {
    border: 1px solid #ddd;
    padding: 1%;
    text-align: left;
    overflow: scroll;
  }
  th {
    font-size: 1.5vw;
    color: white;
    background-color: #054864;
  }
  .button {
    background-color: #f44336;
    color: white;
    border: none;
    padding: 6px 12px;
    cursor: pointer;
  }
  .tablecontainer{
    padding: 1%;
    box-shadow: 2px 2px 12px -2px black;
    width: 70%;
    position: absolute;
    margin-left: 28%;
    margin-top: 6%;
    height: 80vh;
    overflow-y: scroll;
    animation: routinganimationtable .5s   ease-in-out forwards;
  }
  @keyframes routinganimationtable {
    from{
        transform: translateX(-4.5vw);
        opacity: 0;

    }
    to{
        transform: translateX(0vw);
        opacity: 1;

    }
  }
  .clientsearch{
    cursor: pointer;
    position: absolute;
    height: 2.5vw;
    width: 2.5vw;
    padding: .5%;
    border-radius: .4vw;
    margin-left: 28%;
    margin-top: 2%;
    background-color: #054864;

  }
  .clientsearchbox{
    border-radius: .2vw;
    border: 1px solid #054864;
    z-index: 200;
    margin-left: 32%;
    margin-top: 2%;
    position: absolute;
    display: flex;
    height: 3.5vw;
    background-color: #054864;
    backdrop-filter: blur(8px);
    box-shadow: 2px 2px 10px -2px black;
    animation: smooth .3s   ease-in-out forwards;
    width: 30vw;
  }
  @keyframes smooth {
    from{
        width: 0%;
     /* transform: translateX(-5vw); */
    }
    to{
        width: 15%;
       /* transform: translateX(0vw); */
    }
  }
  .clientsinput{
    all: unset;
    padding: 4%;
    all: unset;
    height: 3vw;
    width: 10vw;
    background-color: transparent;
    color: white;
    margin-left: 3%;
    margin-top: 1.9%;
    animation: smoothinput .3s   ease-in-out forwards;
}
.clientsinput::placeholder{
  color: white;
  animation: smoothinput .3s   ease-in-out forwards;
}
  @keyframes smooth {
    from{
        width: 0vw;
     /* transform: translateX(-5vw); */
    }
    to{
        width: 12vw;
       /* transform: translateX(0vw); */
    }
  }
  .searchoutputbox{
   background-color: rgb(234, 255, 234);
   color: green;
   font-size: 1.3vw;
   font-family: "Nunito sans",sans-serif;
   animation: smoothsearch .4s   ease-in-out forwards;
  }
  @keyframes smoothsearch {
    from{
     transform: translateY(20vw);
    }
    to{
       transform: translateY(0vw);
    }
  }

  @media screen and (max-width:412px){
    table {
      border-collapse: collapse;
      width: 100%;
      margin-left:1%
      
    }
    th, td {
      border: 1px solid #ddd;
      padding: 1%;
      text-align: left;
      overflow: scroll;
    }
    td{
      font-size: 2vw;
    }
    th {
      font-size: 2vw;
      color: white;
      background-color: #054864;
    }
    button {
      background-color: #f44336;
      color: white;
      border: none;
      padding: 6px 12px;
      cursor: pointer;
    }
    .tablecontainer{
      padding: 1%;
      box-shadow: 2px 2px 12px -2px black;
      width: 98%;
      position: absolute;
      margin-left: 2.5%;
      margin-top: 30%;
      height: auto;
      overflow-y: scroll;
      animation: routinganimationtable .5s   ease-in-out forwards;
    }
    @keyframes routinganimationtable {
      from{
          transform: translateX(-4.5vw);
          opacity: 0;
  
      }
      to{
          transform: translateX(0vw);
          opacity: 1;
  
      }
    }
    .clientsearch{
      cursor: pointer;
      position: absolute;
      height: 2.5vw;
      width: 2.5vw;
      padding: .5%;
      border-radius: .4vw;
      margin-left: 28%;
      margin-top: -3%;
      background-color: #054864;
      background-color: transparent;
      border-radius: 0px;
  
    }
    .clientsearchbox{
      border-radius: .2vw;
      border: 1px solid #054864;
      z-index: 200;
      margin-left: 32%;
      margin-top: -3%;
      position: absolute;
      display: flex;
      height: 3.5vw;
      background-color: #054864;
      backdrop-filter: blur(8px);
      box-shadow: 2px 2px 10px -2px black;
      animation: smooth .3s   ease-in-out forwards;
      width: 30vw;
     background-color: transparent;
     border-radius: 0px;
    }
    @keyframes smooth {
      from{
          width: 0%;
       /* transform: translateX(-5vw); */
      }
      to{
          width: 15%;
         /* transform: translateX(0vw); */
      }
    }
    .clientsinput{
      all: unset;
      position: fixed;
      top: -1px;
      left: -1px;
      padding: 4%;
      height: 5vw;
      width: 90vw;
      border-radius: 2vw;
      background-color: #16AEE8;
      color: #16AEE8;
      animation: smoothinput .3s   ease-in-out forwards;
      border: 1px solid black;
  }
  .clientsinput::placeholder{
    color: white;
    animation: smoothinput .3s   ease-in-out forwards;
  }
    @keyframes smooth {
      from{
          width: 0vw;
       /* transform: translateX(-5vw); */
      }
      to{
          width: 12vw;
         /* transform: translateX(0vw); */
      }
    }
    .searchoutputbox{
     background-color: rgb(234, 255, 234);
     color: green;
     font-size: 1.3vw;
     font-family: "Nunito sans",sans-serif;
     animation: smoothsearch .4s   ease-in-out forwards;
    }
    @keyframes smoothsearch {
      from{
       transform: translateY(20vw);
      }
      to{
         transform: translateY(0vw);
      }
    }
  
  }