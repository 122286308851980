.analysiscontainer{
    width: 70%;
    height: 91vh;
    border-radius: .7vw;
    position: absolute;
    margin-left: 23%;
    margin-top: 1%;
    animation: showanalysis .4s ease-in-out forwards;
}

@keyframes  showanalysis{
    from{
        opacity: 0;
    }
    to{
        opacity: 1;
    }
    
}
.analysisbox1{
    display: flex;
    width: 100%;
    height: 48vh;
    
}
.analysisbox2{
    display: flex;
    width: 100%;
    height: 48vh;
}
.analysisgraphs{
    color: white;
    background-color: white;
    border-radius: .7vw;
    width: 48%;
    margin: 2%;
    height: 42vh;
    box-shadow: 2px 2px 12px -3px black;
}
.areachart{
   width: 90%;
   height: 10vh; 
   color: white;
}
@media screen and (max-width:412px){
    .analysiscontainer{
        margin-left:0%;
        width: 94%;
        padding:1%;
        height: 91vh;
        border-radius: .7vw;
        position: absolute;
        margin-top:50%;
        animation: showanalysis .4s ease-in-out forwards;
    }
    
    @keyframes  showanalysis{
        from{
            opacity: 0;
        }
        to{
            opacity: 1;
        }
        
    }
    .analysisbox1{
        display:inline-block;
        width: 100%;
        height: 22vh;
        
    }
    .analysisbox2{
        display:inline-block;
        width: 100%;
        height: 22vh;
        margin-top:50%;
    }
    .analysisgraphs{
        margin-left:0%;
        color: white;
        background-color: white;
        border-radius: .7vw;
        width: 100%;
        margin: 2%;
        height: 22vh;
        box-shadow: 2px 2px 12px -3px black;
    }
    .areachart{
       width: 50%;
       height: 10vh; 
       color: white;
    }
    .newuserhead{
        font-size:3vw;
    }
}