.box{
 
    width: 100%;
    display: flex;
    position: absolute;
 
}
.sidebar{
    width: 25%;
    margin-left: 5%;
    height: 100vh;
    margin-top:0%;
    overflow-y: scroll;
    background-color: #074158;
}

.listcontacts{
    width: 100%;
    height: 15vh;
}
.chatsarea{
    margin-top: 7%; 
    width: 70%;
     background-image: url(https://cdn.wallpapersafari.com/27/32/jt4AoG.jpg);
    height: 72.9vh;
    overflow-y: scroll;
    animation: smoothchats .4s   ease-in-out forwards;
}

@keyframes smoothchats {
    from{
     opacity: 0;
    }
    to{
opacity: 1;
    }
}

.recievedtext{
    margin-left: 1%;
    margin-top: -3%;
    width:auto;
    max-width: 100%;
    min-width: 11vw;
    height: auto;
    padding: 1%;
    padding-left: 2%;
    border-radius: .7vw;
    /* border-top-left-radius: 1.5vw;
    border-top-right-radius: .7vw;
    border-bottom-right-radius: 1.5vw; */
    background-color: #074158;
    color: white;
    font-family: "Nunito sans", sans-serif;
    font-size: 1.3vw;
    box-shadow: 2px 2px 4px -2px black;
}
.recieved{
    margin-bottom: 2%;
    display: flex;
    margin-left: 2%;
    margin-top: 4%;
    width: auto;
    max-width:80%;
    height: auto;
    border-radius: .7vw;
    color: white;
    font-family: "Nunito sans", sans-serif;
    font-size: 1.3vw;
}
.singletick{
    height: 2vw;
    width: 2vw;

}

.dummymessage{
    margin-left: 60.3%;
    width: 37%;
    padding: 1%;
    color: white;
    font-family: "Nunito sans", sans-serif;
    font-size: 1.3vw;
    height: auto;
    border-radius: .7vw;
    /* border-top-left-radius: .7vw;
    border-top-right-radius: 1.5vw;
    border-bottom-left-radius: 1.5vw; */
    background-color: #16AEE8;
}
.sent{
    margin-bottom: 1%;
    display: flex;
    text-align: end;
    align-items: end;
    justify-content: end;
    margin-top: 1%;
    max-width: 45%;
    width:auto;
    margin-left: 53%;
    height: auto;
    color: white;
    font-family: "Nunito sans", sans-serif;
    font-size: 1.3vw;
}
.timestampimg{
padding: 2%;
}

.sentimagecontainer{
    padding: 1%;
    background-color: #93defc;
    max-height: 28.5vw;
    max-width: 31vw;
    height: auto;
    width: auto;
    border-radius: .7vw;
    /* margin-left: -22.5%; */
  /* border-top-left-radius: .7vw;
  border-top-right-radius: 1.5vw;
  border-bottom-left-radius: 1.5vw; */
  box-shadow: 2px 2px 4px -2px black;
}

.sentimages{
   /* margin-left: 2%;
   margin-top: 2%; */
    max-height: 25vw;
    height: auto;
    max-width: 29.5vw;
    width: auto;
    border-radius: 1.5vw;
}
.morebox{
    position: absolute;
    z-index: 100;
    margin-left: 58%;
    margin-top: 22%;
    width: 40%;
    height: 20vw;
    box-shadow: 2px 2px 12px -3px black ;
    background-color: white;
    border-radius: 1.5vw;
    animation: showmore .1s  ease-out forwards;
}
@keyframes showmore {
    from{
        transform: translateX(2vw);
    }
    to{
        transform: translateX(0vw);
    }
    
}
.crossicon{
    cursor: pointer;
    position: absolute;
    width: 2vw;
    height: 2vw;
    margin-left: 87%;
    margin-top: 4%;
}
.morehead{
    
    color: #074158;
    padding:4%;
    font-size: 2vw;
    font-weight: bolder;
    font-family: "Nunito sans",sans-serif;
}
.head2{
    margin-top: -6%;
    font-size: 1.1vw;
    font-family: "Nunito sans",sans-serif
}
.notesinput{
    background-color: transparent;
    margin: 4.5%;
    padding: 4%;
    margin-top: -4%;
    font-family: "Nunito sans",sans-serif;
    border: 1px solid #074158;
    width: 80%;
    resize: none;
    height: 4vw;

}
.notesinput::placeholder{
    color: #074158;
}
.savebtn{
    display: flex;
    margin-left: 4.5%;
    width: 40%;
    height: 2.5vw;
    background-color: #074158;
    color: white;
    border-radius: .4vw;
    border: 0px;
    font-size: 1.5vw;
    margin-top: -4%;
    text-align: center;
    align-items: center;
    justify-content: center;
}
.adminmsginput{
    height: 7vw;
}
.recievedimagescontainer{
    border: 1px solid white;
    padding: 1%;
    background-color: #92a2a9;
    max-height: 70vh;
    max-width: 40%;
    height: auto;
    width: auto;
    border-radius: .7vw;
    /* border-top-left-radius: 1.5vw;
    border-top-right-radius: 1.5vw;
    border-bottom-right-radius: 1.5vw; */
    box-shadow: 2px 2px 4px -2px black;
    margin-top: -2%;
}
.recievedimages{
    min-width: 100%;
    min-height:30vh;
    max-height: 96%;
    height: auto;
    max-width: 100%;
    width: auto;
    border-radius: .4vw;
}
.maindoccontainer{
    padding: 1%;
   width: 60%; 
   height:auto;
   border-radius: .7vw;
   /* border-top-left-radius: 1.5vw;
    border-top-right-radius: 1.5vw;
    border-bottom-right-radius: 1.5vw; */
    margin-top: -1%;
   background-color: #92a2a9;
   box-shadow: 2px 2px 4px -2px black;
}
.mainsentdoccontainer{
    padding: 1%;
    width: 95%; 
    height:auto;
    border-radius: .7vw;
    /* border-top-left-radius: 1.5vw;
     border-top-right-radius: 1.5vw;
     border-bottom-left-radius: 1.5vw; */
     background-color: #adccd8;
    box-shadow: 2px 2px 4px -2px black;
}
.doccontainer{
    overflow: hidden;
    display: flex;
    cursor: pointer;
    width: 100%;
    height: auto;
    
    font-family: "Nunito sans",sans-serif;
    background-color: rgb(255, 255, 255);
    border-radius: .7vw;

}
.doc{
    display: inline-block;
    background-color: #16AEE8;
    color: white;
    padding: 2%;
    width: 70%;
    font-size: 1.2vw;

}

.dochead{
    font-size: 2vw;
    color: black;
}
.downloadrecieveddoc{

    margin-left: 3%;
    margin-top: 2%;
    height: 3vw;
    width: 3vw;
    padding: 2%;
}
.videocontainer{
 /* margin-top: 2%;
    width: 100%;
    height: auto; */
    width: 30vw;
    height: auto;
    max-height: 36vw;
    overflow: hidden;
    padding: 2%;
    margin-top: -2%;
    
}
.video{
    /* height: 30vw;
    width: 100%;
    border-radius: .4vw; */
    max-height:50vw;
    height: auto;
    max-height: 35vw;
    width: 100%;
    border-radius: 1vw;
}
.sentvideocontainer{
    background-color: #93defc;
    max-width: 35vw;
    width: auto;
    height: auto;
    max-height: 37vw;
    overflow: hidden;
    padding: 1%;
    border-radius: .7vw;
    margin-top: 2%;
    /* margin-left: -22%;
    margin-top: -13%; */
    /* border-top-left-radius: .7vw;
    border-top-right-radius: 1.5vw;
    border-bottom-left-radius: 1.5vw; */
    box-shadow: 2px 2px 4px -2px black;
}
.sentvideo{
    background-color: white;
    /* margin-top: -58%; */
    max-height:50vw;
    height: auto;
    max-height: 35vw;
    width: 100%;
    border-radius: 1vw;
}
.sentdocname{
    width: 10vw;
    height: auto;
}
.senttext{
    justify-content: start;
    align-items: start;
    text-align: start;
    background-color: #16AEE8;
    max-width: 40vw;
    min-width: 11vw;
    width: auto;
    max-height: auto;
    height: auto;
    padding: 1%;
    padding-left: 2%;
    border-radius: .7vw;
    margin-top: 2%;
    /* border-top-left-radius: .7vw;
    border-top-right-radius: 1.5vw;
    border-bottom-left-radius: 1.5vw; */
    color: white;
    font-family: "Nunito sans", sans-serif;
    font-size: 1.3vw;
    box-shadow: 2px 2px 4px -2px black;

}
.senttextauto{
    max-width: 40vw;
    width: auto;
    max-height: auto;
    height: auto;
    margin-top: 2%;
}
.timestamp{
    display: flex;
    font-size: .8vw;
    text-align: end;
    justify-content: end;
    align-items: end;

}
.senttemplatebox{
    margin-top: 2%;
    background-color:green;
    box-shadow: 2px 2px 4px -2px black;

}
.senttemplateinfo{
    background-color: white;
    width: 96%;
    padding: 1%;
    height: auto;
    color: green;
    border-radius:.4vw;
    font-weight: bolder;
}

.modal{
 text-align: center;
 align-items: center;
 justify-content: center;
    position: absolute;
    z-index: 100;
    width: 100%;
    height: 100vh;  
background-color: rgb(0,0,0,0);
backdrop-filter: blur(30px);
}
.full-image{
    max-width: 60%;
    max-height: 95vh;
    border-radius: .7vw;
    /* width: auto; */
    margin-top: 2vh;
    box-shadow: 2px 2px 4px -2px black;
    border: 3px solid white;
    animation: showfullimg .1s ease-in-out forwards;
}
.thumbnail{
    position: absolute;
    z-index: 100;
  
}

@keyframes showfullimg {
    from{
    transform: translateY(1vh);
    }
    to{
     transform:translateY(0vh);
    }
    
}
  
.close-img{
    cursor: pointer;
    margin-left: 90%;
    margin-top: 2%;
    display: flex;
    text-align: center;
    align-items: center;
    justify-content: center;
    font-size: 2vw;
    position: absolute;
    height: 3vw;
    width: 3vw;
    border-radius: .4vw;
    background-color: red;
    color: white;

}
@media screen and (max-width:996px) {
    .sidebar{
       
        width: 25%;
        margin-left: 5%;
        height: 100vh;
        overflow-y: scroll;
        background-color: #074158;
    }
    .chatsarea{
        margin-top: 7%; 
        width: 70%;
       height: 85vh;
        overflow-y: scroll;
      
    }
    
}

@media screen and (max-width:412px) {
    .box{
 
        width: 100%;
        display: flex;
        position: absolute;
     
    }
    .sidebar{

        width: 100%;
        height: 88vh;
        overflow-y: scroll;
        background-color: #074158;
        margin-left:0%;
        padding-top:8%;
        
    }
    
    .listcontacts{
        width: 100%;
        height: 10vh;
    }
    .chatsarea{
        margin-top: 7%; 
        width: 70%;
         background-image: url(https://cdn.wallpapersafari.com/27/32/jt4AoG.jpg);
        height: 72.9vh;
        overflow-y: scroll;
        animation: smoothchats .4s   ease-in-out forwards;
    }
    
    @keyframes smoothchats {
        from{
         opacity: 0;
        }
        to{
    opacity: 1;
        }
    }
    
    .recievedtext{
        margin-left: 1%;
        margin-top: -1%;
        width:auto;
        max-width: 100%;
        min-width: 20vw;
        height: auto;
        padding: 1%;
        padding-left: 2%;
        border-radius: 2vw;
        /* border-top-left-radius: 1.5vw;
        border-top-right-radius: .7vw;
        border-bottom-right-radius: 1.5vw; */
        background-color: #074158;
        color: white;
        font-family: "Nunito sans", sans-serif;
        font-size: 3vw;
        box-shadow: 2px 2px 4px -2px black;
        font-weight: 700;
    }
    .recieved{
        margin-bottom: 2%;
        display: flex;
        margin-left: 2%;
        margin-top: 3%;
        width: auto;
        max-width:80%;
        height: auto;
        border-radius: .7vw;
        color: white;
        font-family: "Nunito sans", sans-serif;
        font-size: 1.3vw;
    }
    .singletick{
        height: 2vw;
        width: 2vw;
    }
    
    .dummymessage{
        margin-left: 60.3%;
        width: 37%;
        padding: 1%;
        color: white;
        font-family: "Nunito sans", sans-serif;
        font-size: 1.3vw;
        height: auto;
        border-radius: .7vw;
        /* border-top-left-radius: .7vw;
        border-top-right-radius: 1.5vw;
        border-bottom-left-radius: 1.5vw; */
        background-color: #16AEE8;
    }
    .sent{
        margin-bottom: 1%;
        display: flex;
        text-align: end;
        align-items: end;
        justify-content: end;
        margin-top: 1%;
        max-width: 80%;
        width:auto;
        margin-left: 17%;
        height: auto;
        color: white;
        font-family: "Nunito sans", sans-serif;
        font-size: 4vw;
    }
    .timestampimg{
    padding: 2%;
    }
    
    .sentimagecontainer{
        padding: 1%;
        background-color: #93defc;
        max-height: 80vh;
        max-width: 90%;
        height: auto;
        width: auto;
        border-radius: .7vw;
        /* margin-left: -22.5%; */
      /* border-top-left-radius: .7vw;
      border-top-right-radius: 1.5vw;
      border-bottom-left-radius: 1.5vw; */
      box-shadow: 2px 2px 4px -2px black;
    }
    
    .sentimages{
       /* margin-left: 2%;
       margin-top: 2%; */
       max-height: 80vh;
       height: auto;
       max-width: 100%;
       width: auto;
        border-radius: 1.5vw;
    }
    .morebox{
        position: absolute;
        z-index: 100;
        margin-left: 58%;
        margin-top: 40%;
        width: 40%;
        height: auto;
        box-shadow: 2px 2px 12px -3px black ;
        background-color: white;
        border-radius: 1.5vw;
        animation: showmore .1s  ease-out forwards;
    }
    @keyframes showmore {
        from{
            transform: translateX(2vw);
        }
        to{
            transform: translateX(0vw);
        }
        
    }
    .crossicon{
        cursor: pointer;
        position: absolute;
        width: 2vw;
        height: 2vw;
        margin-left: 87%;
        margin-top: 4%;
    }
    .morehead{
        
        color: #074158;
        padding:4%;
        font-size: 3vw;
        font-weight: bolder;
        font-family: "Nunito sans",sans-serif;
    }
    .head2{
        margin-top: -6%;
        font-size: 1.1vw;
        font-family: "Nunito sans",sans-serif
    }
    .notesinput{
        background-color: transparent;
        margin: 4.5%;
        padding: 4%;
        margin-top: -4%;
        font-family: "Nunito sans",sans-serif;
        border: 1px solid #074158;
        width: 80%;
        resize: none;
        height: 4vw;
    
    }
    .notesinput::placeholder{
        color: #074158;
    }
    .savebtn{
        display: flex;
        margin-left: 4.5%;
        width: 40%;
        height: 2.5vw;
        background-color: #074158;
        color: white;
        border-radius: .4vw;
        border: 0px;
        font-size: 1.5vw;
        margin-top: -4%;
        text-align: center;
        align-items: center;
        justify-content: center;
    }
    .adminmsginput{
        height: 7vw;
    }
    .recievedimagescontainer{
        border: 1px solid white;
        padding: 2%;
        background-color: #92a2a9;
        max-height: 80vh;
        max-width: 90%;
        height: auto;
        width: auto;
        border-radius: .7vw;
        /* border-top-left-radius: 1.5vw;
        border-top-right-radius: 1.5vw;
        border-bottom-right-radius: 1.5vw; */
        box-shadow: 2px 2px 4px -2px black;
    }
    .recievedimages{
        max-height: 80vh;
        height: auto;
        max-width: 100%;
        width: auto;
        border-radius: .4vw;
    }
    .maindoccontainer{
        padding: 1%;
       width: 95%; 
       height:auto;
       border-radius: .7vw;
       /* border-top-left-radius: 1.5vw;
        border-top-right-radius: 1.5vw;
        border-bottom-right-radius: 1.5vw; */
       background-color: #92a2a9;
       box-shadow: 2px 2px 4px -2px black;
    }
    .mainsentdoccontainer{
        padding: 1%;
        width: 75%; 
        height:auto;
        border-radius: .7vw;
        /* border-top-left-radius: 1.5vw;
         border-top-right-radius: 1.5vw;
         border-bottom-left-radius: 1.5vw; */
         background-color: #adccd8;
        box-shadow: 2px 2px 4px -2px black;
    }
    .doccontainer{
        overflow: hidden;
        display: flex;
        cursor: pointer;
        width: 100%;
        height: auto;
        
        font-family: "Nunito sans",sans-serif;
        background-color: rgb(255, 255, 255);
        border-radius: .7vw;
    
    }
    .doc{
        display: inline-block;
        background-color: #16AEE8;
        color: white;
        padding: 2%;
        width: 70%;
        font-size: 3vw;
    
    }
    
    .dochead{
        font-size: 3vw;
        color: black;
    }
    .downloadrecieveddoc{
    
        margin-left: 5%;
        margin-top: 1%;
        height: 7vw;
        width: 7vw;
        padding: 2%;
    }
    .videocontainer{
     /* margin-top: 2%;
        width: 100%;
        height: auto; */
        width: 30vw;
        height: auto;
        max-height: 80vh;
        max-width: 90%;
        width: auto;
        height: auto;
        overflow: hidden;
        padding: 2%;
        
    }
    .video{
        /* height: 30vw;
        width: 100%;
        border-radius: .4vw; */
        max-height:80vh;
        height: auto;
        max-width: 100%;
        width: auto;
        border-radius: 1vw;
    }
    .sentvideocontainer{
        background-color: #93defc;
        width: 30vw;
        height: auto;
        max-height: 80vh;
        max-width: 90%;
        width: auto;
        height: auto;
        overflow: hidden;
        padding: 1%;
        border-radius: .7vw;
        margin-top: 2%;
        /* margin-left: -22%;
        margin-top: -13%; */
        /* border-top-left-radius: .7vw;
        border-top-right-radius: 1.5vw;
        border-bottom-left-radius: 1.5vw; */
        box-shadow: 2px 2px 4px -2px black;
    }
    .sentvideo{
        background-color: white;
        /* margin-top: -58%; */
        max-height:80vh;
        height: auto;
        max-width: 100%;
        width: auto;
        border-radius: 1vw;
    }
    .sentdocname{
        width: 10vw;
        height: auto;
        
    }
    .senttext{
        justify-content: start;
        align-items: start;
        text-align: start;
        background-color: #16AEE8;
        max-width: 100%;
        min-width: 20vw;
        width: auto;
        max-height: auto;
        height: auto;
        padding: 1%;
        padding-left: 2%;
        border-radius: 2vw;
        /* border-top-left-radius: .7vw;
        border-top-right-radius: 1.5vw;
        border-bottom-left-radius: 1.5vw; */
        color: white;
        font-family: "Nunito sans", sans-serif;
        font-size: 3vw;
        box-shadow: 2px 2px 4px -2px black;
        font-weight: 700;
    
    }
    .senttextauto{
        max-width: 90%;
        width: auto;
        margin-top: 2%;
        height: auto;
    }
    .timestamp{
        display: flex;
        font-size: .8vw;
        text-align: end;
        justify-content: end;
        align-items: end;
        font-size: 2vw;
    
    }
    .senttemplatebox{
        background-color:green;
        box-shadow: 2px 2px 4px -2px black;
    }
    .senttemplateinfo{
        background-color: white;
        width: 96%;
        padding: 1%;
        height: auto;
        color: green;
        border-radius:.4vw;
        font-weight: bolder;
    }
    
    .modal{
     text-align: center;
     align-items: center;
     justify-content: center;
        position: absolute;
        z-index: 100;
        width: 100%;
        height: 100vh;  
    background-color: rgb(0,0,0,0);
    backdrop-filter: blur(30px);
    }
    .full-image{
        max-width: 60%;
        max-height: 95vh;
        border-radius: .7vw;
        /* width: auto; */
        margin-top: 2vh;
        box-shadow: 2px 2px 4px -2px black;
        border: 3px solid white;
        animation: showfullimg .1s ease-in-out forwards;
    }
    .thumbnail{
        position: absolute;
        z-index: 100;
      
    }
    
    @keyframes showfullimg {
        from{
        transform: translateY(1vh);
        }
        to{
         transform:translateY(0vh);
        }
        
    }
      
    .close-img{
        cursor: pointer;
        margin-left: 90%;
        margin-top: 2%;
        display: flex;
        text-align: center;
        align-items: center;
        justify-content: center;
        font-size: 2vw;
        position: absolute;
        height: 3vw;
        width: 3vw;
        border-radius: .4vw;
        background-color: red;
        color: white;
    }    
}