.namepopup {
  width: 30%;
  height: 25vh;
  background-color: white;
  box-shadow: 2px 2px 12px -3px black;
  border-radius: 0.7vw;
  position: absolute;
  z-index: 2000;
  margin-left: 35%;
  margin-top: 30vh;
  padding: 1%;
  animation: shownameinput 0.1s ease-in-out forwards;
}
.flowinput{
  all: unset;
  width: 95%;
  height: 4vh;
  border: 1px solid black;
  border-radius: .7vw;
  padding: 2%;
  color: black;
}
.savebtn2{
  width: 95%;
  height: 4vh;
  display: flex;
  text-align: center;
  align-items: center;
  justify-content: center;
  color: white;
  padding: 2%;
  font-size: 1.5vw;
  border-radius: .7vw;
  margin-top: 5%;
  background-color: #16aee8;

}
.savebtn2:hover{
  background-color: #333;
  transition-duration: .4s;
}

.flowinput::placeholder{
  color: black;
}
.flowlable{
  font-size: 1.5vw;
  color: black;
}


@keyframes shownameinput {
  from {
    scale: 0;
  }
  to {
    scale: 1;
  }
}

.aimaincontainer {
  position: absolute;
  margin-left: 17%;
  width: 86%;
  height: 100vh;
  background-color: rgb(222, 222, 222);
  overflow-y: scroll;
  background-color: #e8f7fd;
}
.container {
  width: 50%;
  height: 100vh;
  border: 1px solid black;
  margin-left: 2%;
}
.elementsofdrag {
  width: 40%;
  margin: 2%;
  height: 3vw;
  padding: 1%;
  background-color: black;
  color: white;
  border-radius: 0.4vw;
}
.react-flow__edge-path {
  stroke: #333;
  stroke-width: 2;
}

.temp .react-flow__edge-path {
  stroke: #bbb;
  stroke-dasharray: 5 5;
}
.templatenodebox {
  width: auto;
  max-width: 20vw;
  /* height: auto; */
  min-width: 20vw;
}
.myaiflows{
  border: 1px solid #16aee8;
  z-index: 1000;
  position: absolute;
  width: 15%;
  height: 14vh;
  margin-left: 1%;
  border-radius: .7vw;
  margin-top: 1vh;
  background-color: rgb(0, 0, 0, 0.3);
  backdrop-filter: blur(7px);
  box-shadow: 2px 2px 12px -3px black;
}
.myflowshead{

  color: white;
  font-size: 1.1vw;
  padding: 1%;
  margin-left: 2%;
  width: 90%;
  background-color: transparent;
  backdrop-filter: blur(8px);
}
.myflowsmaincontainer{
  overflow-y: scroll;
  width: 95%;
  height: 9.5vh;
}
.myflowscontainer{
margin-left: 2%;


}
.myflowname{
  font-size: 1.2vw;
  color: #16aee8;
  width: 100%;
  height: auto;
  cursor: pointer;
}

.aitemplatescontainer {
  overflow: scroll;
  border: 1px solid #16aee8;
  z-index: 1000;
  position: absolute;
  width: 15%;
  height: 80vh;
  margin-left: 1%;
  border-radius: 1vw;
  margin-top: 17vh;
  background-color: rgb(0, 0, 0, 0.3);
  backdrop-filter: blur(7px);
  box-shadow: 2px 2px 12px -3px black;
}
.aimenuicon {
  height: 2vw;
  width: 2vw;
  padding: 1%;
}
.templateboxactive {
  width: 30%;
  height: 80vh;
}
.aitemplatesbox {
  z-index: 2000;
  width: 95%;
  height: auto;
  padding: 1%;
}
.templatebox1 {
  display: flex;
}
.inputtemplate {
  width: auto;
  height: auto;
  padding: 1%;
  background-color: #16aee8;
  text-align: center;
  align-items: center;
  justify-content: center;
  color: white;
  border-radius: 0.4vw;
}
.nodes {
  width: auto;
  height: auto;
  max-width: 100%;
  background-color: white;
  border-radius: 0.3vw;
  padding: 1%;
  font-size: 1.3vw;
  margin-top: 2%;
  margin-left: 2%;
  cursor: pointer;
}
.aipreviewtemplate {
  margin-top: 2%;
  border-radius: 1vw;
  height: auto;
  width: auto;
}
.react-flow__node-selectorNode {
  font-size: 12px;
  background: #eee;
  border: 1px solid #555;
  border-radius: 5px;
  text-align: center;
}

.templatenode {
  overflow: hidden;
  height: auto;
  width: auto;
  max-width: 100%;
  min-width: 100%;
  height: auto;
  min-height: 20vh;
  max-height: 50vh;
  background-color: rgb(192, 192, 192);
  border-radius: 1vw;
}

.templatedata {
  width: 100%;

  height: auto;
  background-color: white;
  padding: 1%;
}

.aitemphead {
  display: inline-block;
  font-size: 1.5vw;
  font-weight: bolder;
  color: black;
  padding: 2%;
}
.aitempmessage {
  display: inline-block;
  font-size: 1.2vw;
  color: black;
  padding: 2%;
  /* margin-top: -1%; */
  width: auto;
  height: auto;
  background-color: #e3e3e3;
  border-radius: 0.7vw;
  margin: 2%;
  margin-left: 0%;
}
.aitempbuttons {
  margin-top: 4%;
  width: 100%;
  height: auto;
}
.aitempbtns {
  width: 90%;
  height: auto;
  background-color: white;
  padding: 1%;
  display: flex;
  text-align: center;
  align-items: center;
  justify-content: center;
  color: black;
  margin-top: 0.5%;
  border: 1px solid black;
  font-size: 1.5vw;
  border-radius: 0.7vw;
  margin-left: 3%;
}
.outputhandle {
  margin-left: -30%;
  margin-top: 0.5%;

  height: 10vw;
  width: 10vw;
  background-color: #16aee8;
  fill: #16aee8;
  color: #16aee8;
}
.inputhandle1 {
  margin-left: 20%;
  margin-top: 32%;

  height: 10vw;
  width: 10vw;
  background-color: #16aee8;
}
.inputhandle2 {
  margin-left: 38%;
  margin-top: 33.5%;

  height: 10vw;
  width: 10vw;
  background-color: #16aee8;
}
.inputhandle3 {
  margin-left: 38%;
  margin-top: 39%;

  height: 10vw;
  width: 10vw;
  background-color: #16aee8;
}
.inputhandle4 {
  margin-left: -10.5%;
  margin-top: 28%;

  height: 10vw;
  width: 10vw;
  background-color: #16aee8;
}
.inputhandle5 {
  margin-left: -10.5%;
  margin-top: 22.3%;

  height: 10vw;
  width: 10vw;
  background-color: #16aee8;
}

/* for simple text */

.simpletextaitemp {
  overflow: hidden;
  min-height: 20vh;
  max-width: 100%;
  min-width: 100%;
  width: 100%;
  height: auto;
  background-color: white;
  border-radius: 1vw;
}
.simpletext {
  width: 92%;
  height: auto;
  min-height: 5vh;
  background-color: #d1d8da;
  color: black;
  margin: 2%;
  padding: 2%;
  font-size: 1.2vw;
  border-radius: 0.7vw;
  margin-top: 2%;
}
.simpletextlable {
  font-size: 1vw;
  color: black;
  margin-left: 3%;
}
.textinput {
  all: unset;
  border: 1px solid black;
  width: 91%;
  padding: 2%;
  border-radius: 0.7vw;
  margin-left: 2%;
  white-space: pre-wrap; /* Allow text to wrap */
  word-wrap: break-word;
}
.savetextbtn {
  color: white;
  background-color: #16aee8;
  font-size: 1.3vw;
  display: flex;
  text-align: center;
  align-items: center;
  justify-content: center;
  width: 100%;
  cursor: pointer;
  height: 3vw;
}
.savetextbtn:hover {
  background-color: #333;
  transition-duration: 0.3s;
}
.flowstart {
  font-size: 1vw;
  color: white;
  padding: 3%;
  margin: 2%;
  background-color: #a6a6a6;
  border-radius: 0.7vw;
}
.incomingtextmessage {
  font-size: 1vw;
  padding: 2%;
  margin: 2%;
  height: 5vh;
  background-color: #16aee8;
  border-radius: 0.7vw;
  color: white;
  display: flex;
  text-align: center;
  align-items: center;
  justify-content: center;
}
.simple {
  background-color: #16aee8;
  color: white;
  border-radius: 0.7vw;
  border: 1px solid #16aee8;
  padding: 2%;
}
.flowstarter {
  background-color: green;
  color: white;
  border-radius: 0.7vw;
  border: 1px solid rgb(2, 87, 2);
  padding: 2%;
}
.newflow{
  z-index: 20;
  position: absolute;
  width: 7%;
  padding: 1%;
  height: 2vh;
  display: flex;
  text-align: center;
  align-items: center;
  justify-content: center;
  background-color: green;
  color: white;
  border-radius: 0.4vw;
  font-size: 1.3vw;
  margin-left: 77%;
  margin-top: 1%;
  cursor: pointer;

}
.activeflowname{
  z-index: 20;
  position: absolute;
  width: auto;
  padding: 1%;
  height: 2vh;
  display: flex;
  text-align: center;
  align-items: center;
  justify-content: center;
  background-color: green;
  color: white;
  border-radius: 0.4vw;
  font-size: 1.3vw;
  margin-left: 18%;
  margin-top: 1%;
  cursor: pointer;
}
.saveflowbtn {
  z-index: 20;
  position: absolute;
  width: 7%;
  padding: 1%;
  height: 2vh;
  display: flex;
  text-align: center;
  align-items: center;
  justify-content: center;
  background-color: #16aee8;
  color: white;
  border-radius: 0.4vw;
  font-size: 1.3vw;
  margin-left: 87%;
  margin-top: 1%;
  cursor: pointer;
}

.chatbotnode{
  width: 20vw;
  min-width:20vw;
  max-width: 20vw;
  height: auto;
  padding: 2%;
  border-radius: .4vw;
  background-color: rgb(237, 237, 237);
}
.chatbotnodedata{


}
.targethandle{
  display: inline;
  text-align: center;
  align-items: center;
  justify-content: center;
  margin-left: 0%;

}
.nodelabel{
  color: white;
}
.nodequestion{
  background-color: #9ed2e7;
  padding: 1%;
  color: black;
  font-size: 1.2vw;
  border-radius: .4vw;
}
.nodeanswer{
  background-color: #3c88a7;
  padding: 1%;
  color: black;
  font-size: 1.2vw;
  border-radius: .4vw;
  margin-top: 1%;
}

@media screen and (max-width: 412px) {
  .aimaincontainer {
    position: absolute;
    width: 100%;
    height: 85vh;
    background-color: rgb(222, 222, 222);
    overflow-y: scroll;
    background-color: #e8f7fd;
    margin-left: 0%;
    margin-top: 32%;
  }
  .container {
    width: 100%;
    height: 100vh;
    border: 1px solid black;
    margin-left: 2%;
  }
  .elementsofdrag {
    width: 40%;
    margin: 2%;
    height: 3vw;
    padding: 1%;
    background-color: black;
    color: white;
    border-radius: 0.4vw;
    font-size: 3vw;
  }
  .react-flow__edge-path {
    stroke: #333;
    stroke-width: 2;
  }

  .temp .react-flow__edge-path {
    stroke: #bbb;
    stroke-dasharray: 5 5;
  }

  .aitemplatescontainer {
    z-index: 1000;
    position: absolute;
    width: 30%;
    height: auto;
    margin-left: 1%;
    padding-bottom: 5%;
    padding: 2%;
    border-radius: 1vw;
    margin-top: 2vh;
    background-color: rgb(0, 0, 0, 0.3);
    backdrop-filter: blur(7px);
    box-shadow: 2px 2px 12px -3px black;
  }
  .aimenuicon {
    height: 2vw;
    width: 2vw;
    padding: 1%;
  }
  .templateboxactive {
    width: 30%;
    height: 80vh;
  }
  .aitemplatesbox {
    z-index: 2000;
    width: 95%;
    height: auto;
    padding: 1%;
  }
  .templatebox1 {
    display: flex;
  }
  .inputtemplate {
    width: auto;
    height: auto;
    padding: 1%;
    background-color: #16aee8;
    text-align: center;
    align-items: center;
    justify-content: center;
    color: white;
    border-radius: 0.4vw;
  }
  .nodes {
    width: auto;
    height: auto;
    max-width: 40%;
    background-color: white;
    border-radius: 0.5vw;
    padding: 1%;
    font-size: 2vw;
    margin-top: 2%;
    cursor: pointer;
  }
  .aipreviewtemplate {
    margin-top: 2%;
    border-radius: 1vw;
    height: auto;
    width: auto;
  }
  .react-flow__node-selectorNode {
    font-size: 12px;
    background: #eee;
    border: 1px solid #555;
    border-radius: 5px;
    text-align: center;
  }
  .alert{
    width: 90%;
    height: auto;
    padding: 2%;
    border-radius: 2vw;
    background-color: red;
    color: white;
    display: flex;
    text-align: center;
    align-items: center;
    justify-content: center;
    font-size: 3vw;
    margin-left: 3%;
    margin-top: 70%;


  }
}
