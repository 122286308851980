.settingshead{
    position: absolute;
    font-size: 4vw;

    display: flex;
    text-align: center;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 5vw;
    background-color: #16AEE8;
    z-index: -1;
    color: #06435b;
}