.userscontainer{
    padding: 1%;
    width: 67%;
    position: absolute;
    height: auto;
    margin-left: 30%;
    animation: showusers .4s  ease-in-out forwards;

}
@keyframes showusers {
    from{
opacity: 0;
    }
    to{
opacity: 1;
    }
    
}
.usersearchcontainer{
    position: fixed;
    border-radius: .3vw;
   
    width: 50%;
    height: 4vw;
    background-color: #054864;
}
.usersearchcontainer:hover{
    .usersearchicon{
        margin-left: 90%;
        transition-duration: .5s;
    }
}
.usersearch{
    all: unset;
    position: absolute;
    height: 3vw;
    width: 80%;
    margin-top: .65%;
    margin-left: 1%;
    color: white;
    font-size: 1.5vw;
}
.usersearch::placeholder{
color: white;
}

.usersearchicon{
    margin-left: 88%;
    height: 4vw;
    width:4vw;
    fill: white;
}
.addnewbtn{
    margin-left:53%;
    display: flex;
    text-align: center;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    font-size: 2vw;
    color: white;
    width: 15%;
    height: 4vw;
    border-radius: .4vw;
    background-color: #16AEE8;
    position: fixed;
}
.addnewbtn:hover{
    transition-duration: .3s;
    background-color: #0d688c;
}
.crusers{
width: 100%;
margin-top: 10%;
}
.deletebtn{
    border-radius: .3vw;
}