.templatepopup{
    width: 80%;
    height: 90vh;
}
.templateclose{
    border-radius: .4vw;
    color: white;
    background-color: red;
}
.templateselectionbox{
    display: flex;
    padding: 2%;
    width: 95%;
    height: 83vh;
}
.availabletemplatesbox{

    height: 84vh;
    width: 40%;
}
.availablehead{
   font-weight: bolder;
     font-size: 2vw;
     color: #032330;
     margin-left: 2%;

}
.availabletemplates{
    padding: 4%;
    margin-top: -4%;
    height: 80vh;
    width: 70%;
    background-color: #032330;
    border-radius: .4vw;
    color: white;
    overflow: scroll;

}
.sendingtemplates{
    margin-top: -1.5%;
    display: flex;
    height: 84vh;
    width: 90%;
    background-color: #c3eeff;
    border-radius: .4vw;
    color: white;
    overflow: scroll;
}
.templatearch{
    cursor: pointer;
    margin-top: 10%;
    padding: 2%;
    font-size: 1.3vw;
    font-weight: bold;
    color: #032330;
    border-radius: .4vw;
    background-color: #c3eeff;
}
.selectedtemplate{
    padding: 4%;
    width: 50%;
}
.sendtemplatebtn{
    cursor: pointer;
    position: absolute;
    margin-left: 54.2%;
    margin-top: -5%;
    font-size:2vw;
    font-weight: bolder;
    width: 20%;
    height: 3vw;
    display: flex;
    text-align: center;
    align-items: center;
    justify-content: center;
    background-color: #16AEE8;
    color: white;
    border-radius: .4vw;
    box-shadow: 2px 2px 12px -3px black;
}
.sendtempinfo{
    font-size: 1.2vw;
    color: black;
     
}
.tempheader{
    all: unset;
    color: black;
    font-size: 1.8vw;
    font-weight: bolder;
    width: 98%;
    height: 3vw;
}

.tempmessage{
    all: unset;
    color: black;
    font-size: 1.5vw;
    width: 100%;
    height: 20vw;
    margin-top: 0%;
}
.selectedtemplatevariables{
    width: 35%;
    height: 71vh;
    padding: 2%;
    margin: 3%;
    background-color: #73d8fd;
    border-radius: .4vw;

}

.tempheadervars{
  
    font-size: 1.5vw;
    color: white;
}
.headervarsinput{
    all: unset;
    border: 1px solid white;
    border-radius: .4vw;
    font-size: 1.2vw;
    color: white;
    padding: 4%;
    width: 90%;
}
.hvarinfo{
    color:white;
    font-size: 1.25vw;  
}
.tempbodyvars{
    font-size: 1.5vw;
    color: white;
}
.bodyvarsinputs{
    all: unset;
    border: 1px solid white;
    border-radius: .4vw;
    font-size: 1.2vw;
    color: white;
    padding: 4%;
    height: 10vw;
    width: 90%;
    margin-top: 3%;
}
.variablesavebtn{
    width: 100%;
    height: 3vw;
    display: flex;
    text-align:center;
    align-items: center;
    justify-content: center;
    color: white;
    background-color: #0b678b;
    border-radius: .4vw;
    font-size: 1.5vw;
}

/* loader */

.loader-container {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .loader {
    border: 4px solid white; /* Green color with some transparency */
    border-top: 4px solid #16AEE8; /* Green color */
    border-radius: 50%;
    width: 2vw;
    height: 2vw;
    animation: spin 1s linear infinite; /* Use the spin animation for rotation */
  }
  
  @keyframes spin {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }