
 .chatstopbar{
    margin-left: 30%;
    position: absolute;
width: 70%;
height: 7vw;
background-color: #16AEE8;
animation: smoothchats .4s   ease-in-out forwards;

 }
 
  @keyframes smoothshow {
    from{
        opacity: 0;
     transform: translateY(-3vw);
    }
    to{
        opacity: 1;
       transform: translateY(0vw);
    }
  }

 .profileicon{
    position: absolute;
    height: 6vw;
    width: 6vw;
    background-color: #06384e;
    color: white;
    font-size: 4vw;
    font-weight: bolder;
    text-align: center;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    margin-top: .8%;
    margin-left: 1%;

 }
 .profilename{
    color: #06384e;
    font-size: 1.5vw;
    margin-top: 3%;
    margin-left: 11%;
    position: absolute;
    font-weight: bolder;
    width: 60%;

 }
 .profilephone{
    position: absolute;
    margin-top: 5.5%;
    margin-left: 11%;
    color: white;
    font-size: 1vw;
 }
 .phonecontainer{
    position: absolute;
    width: 15vw;
    height: 5vw;
    border-radius: .7vw;
    /* background-color: white; */
    background-color: #3ac7ff;
    margin-left: 75%;
    margin-top: 2%;

 }
 .phoneicon{
    cursor: pointer;
    position: absolute;
height: 3vw;
width: 3vw;
margin-top: 6%;
margin-left: 14%;
 }
 .adminmsg{
    cursor: pointer;
   
height: 3vw;
width: 3vw;
margin-top: 6%;
margin-left: 12%;
 }

 .threedots{
    position: absolute;
    cursor: pointer;
  margin-left: 42%;
  height: 3vw;
width: 3vw;
margin-top: 6%;
 }
 .messagearea{
    background-color: white;
    margin-left: 30%;
    position: fixed;
    margin-top:90vh;
    width: 80%;
    animation: smoothchats .4s   ease-in-out forwards;
 }
 .message-input{
    all: unset;
   width: 60%;
   font-size: 1.4vw;
   margin-left: 15%;
   background-color: #06384e;
   height: 2.2vw; 
   border: 0px;
   color: white;
   border-radius: 4vw;
   padding: 1%;
   margin-top: .3%;
   
}
.message-input::placeholder{
  color: white;
}
.file-element{
    position: absolute;
width: 14%;
height: 4.5vw;
margin-top: .5%;
}

.send-button{
    position: absolute;
    width: 4.5vw;
    height: 4.1vw;
    font-size: 1.4vw;
    margin-left: 1%;
    text-align: center;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    background-color: white;
    background-color: #06384e;
    color: white;

}
.sendicon{
    width: 3vw;
    height: 3vw;
   display: flex;
   text-align: center;
   align-items: center;
   justify-content: center;
    cursor: pointer;
}

.emojiicon{
 width: 3vw;
 height: 3.5vw; 
 margin-left: 2%;
 margin-top: 0%;
 cursor: pointer;
 
}
.emojiicon2{
    width: 3vw;
    height: 3vw; 
    margin-left: 10%;
 
    cursor: pointer;
    
   }

.fileicon{
    margin-top: 1%;
    width: 3vw;
    height: 2.7vw; 
    margin-left: 7%;
    cursor: pointer;
}
.emojiscontainer{
    z-index: 3000;
    margin-left: 20%;
    margin-top: 11%;
    position: absolute;
    overflow: scroll;
    padding: 1%;
    width: 30%;
    height: 60vh;
    background-color: rgb(0,0,0,0.5);
    backdrop-filter: blur(10px);

    box-shadow: 2px 2px 12px -2px black;
    animation: showemojis .3s ease-in-out forwards;
}

@keyframes  showemojis{
    from{
        height: 0vh;
/* transform: translateY(10%); */
    }
    to{
        height: 60vh;
        /* transform: translateY(0%); */
    }
    
}

.emoji{
    font-size: 3vw;
    cursor: pointer;
}
.chatsentrycontainer{
    position: absolute;
    width: 50%;
    height: 20vw;


    text-align: center;
    align-items: center;
    justify-content:center;;
    margin-left: 40%;
    margin-top: 10%;
    border-radius: 1vw;
    background-color: #d4eef8;
}
.chatscontainerhead{
    font-size: 3vw;
    font-family: "Nunito sans", sans-serif;
    font-weight: bolder;
    color: #06384e;
    margin-top: 10%;
}
.chatscontainerinfo{
    font-size: 2vw;
    font-family: "Nunito sans", sans-serif;
    font-weight: bolder;
    color: #16AEE8;
}
@media screen and (max-width:996px) {
    .messagearea{
        margin-left: 30%;
        position: fixed;
        margin-top:90%;
        width: 80%;
        border-top: 2px solid #06384e;
     }
     .message-input{
        all: unset;
       width: 60%;
       font-size: 2vw;
       margin-left: 15%;
       height: 4vw; 
       border: 0px;
       margin-top: 2%;
       
    }
    .message-input::placeholder{
        color:#06384e
    }
    .file-element{
        position: absolute;
    width: 14%;
    height: 4.5vw;
    margin-top: 2%;
    }
    .profileicon{
        position: absolute;
        height: 13vw;
        width: 13vw;
        background-color: #06384e;
        color: white;
        font-size: 8vw;
        font-weight: bolder;
        text-align: center;
        align-items: center;
        justify-content: center;
        border-radius: 50%;
        margin-top: .8%;
        margin-left: 1%;
    
     }
     .profilename{
        color: #06384e;
        font-size: 5vw;
        margin-top: 3%;
        margin-left: 15%;
        position: absolute;
        font-weight: bolder;
        width: 60%;
    
     }
     .profilephone{
        position: absolute;
        margin-top: 9%;
        margin-left: 15%;
        color: white;
        font-size: 2vw;
     }
     .phonecontainer{
        position: absolute;
        width: 20vw;
        height: 7vw;
        border-radius: .7vw;
        /* background-color: white; */
        background-color: #3ac7ff;
        margin-left: 75%;
        margin-top: 4%;
    
     }
     .phoneicon{
        cursor: pointer;
        position: absolute;
    height: 4vw;
    width: 4vw;
    margin-top: 6%;
    margin-left: 14%;
     }
     .adminmsg{
        cursor: pointer;
       
    height: 4vw;
    width: 4vw;
    margin-top: 6%;
    margin-left: 12%;
     }
    
     .threedots{
        position: absolute;
        cursor: pointer;
      margin-left: 42%;
      height: 4vw;
    width: 4vw;
    margin-top: 6%;
     }
    
    .send-button{
        position: absolute;
        width: 13%;
        height: 4.5vw;
        font-size: 1.4vw;
        text-align: center;
        align-items: center;
        justify-content: center;
        border: 0px;
        /* background-color: #16AEE8; */
        background-color: white;
        color: white;
    
    }
    .sendicon{
        width: 6vw;
        height: 6vw;
        margin-top: 7%;
        cursor: pointer;
    }
    
    .emojiicon{
     width: 3vw;
     height: 3vw; 
     margin-left: 12%;
     margin-top: 6%;
     cursor: pointer;
     
    }
    .fileicon{
        width: 3vw;
        height: 3vw; 
        margin-left: 15%;
        cursor: pointer;
    }
    .chatsentrycontainer{
        position: absolute;
        width: 50%;
        height: 20vw;
    
    
        text-align: center;
        align-items: center;
        justify-content:center;;
        margin-left: 40%;
        margin-top: 10%;
        border-radius: 1vw;
        background-color: #d4eef8;
    }
    .chatscontainerhead{
        font-size: 3vw;
        font-family: "Nunito sans", sans-serif;
        font-weight: bolder;
        color: #06384e;
        margin-top: 10%;
    }
    .chatscontainerinfo{
        font-size: 2vw;
        font-family: "Nunito sans", sans-serif;
        font-weight: bolder;
        color: #16AEE8;
    }
    
 }


 @media screen and (max-width:412px) {
    .chatstopbar{
        position: fixed;
        margin-left: 0%;
        top: 0;
    width: 100%;
    height: 7vh;
    background-color: #16AEE8;
    animation: smoothchats .4s   ease-in-out forwards;
    z-index: 100000;
    
     }
    .messagearea{
        position: fixed;
        bottom: 1vh;
        width: 100%;
        margin-left: 0%;
        height: 6vh;
         /* border: 2px solid #06384e;  */
        border-top: none;
     }
     .message-input{
        all: unset;
        width: 55%;
   font-size: 4vw;
   margin-left: 23%;
   background-color: #16AEE8;
   height: 9vw; 
   border: 0px;
   color: white;
   border-bottom-right-radius: 5vw;
   border-top-right-radius: 5vw;
   padding: 1%;
   padding-left: 5%;
       margin-top: 2%;
       padding-right: 15%;
    }
    .chatsarea{
        height: 85vh;
        margin-top: 15%;
        width: 100%;
        border-bottom: 0px;
        padding-bottom: 5%;
    }

    .message-input::placeholder{
        color:white
    }
    .file-element{
        position: absolute;
        display: flex;
        text-align: center;
        align-items: center;
        justify-content: center;
    width: 20%;
    background-color: #16AEE8;
    height: 7vw;
    margin-top: 2%;
    margin-left: 2%;
    border-bottom-left-radius: 5vw;
    border-top-left-radius: 5vw;
    padding: 2%;
    }
    .profileicon{
        position: absolute;
        height: 13vw;
        width: 13vw;
        background-color: #06384e;
        color: white;
        font-size: 8vw;
        font-weight: bolder;
        text-align: center;
        align-items: center;
        justify-content: center;
        border-radius: 50%;
        margin-top: .8%;
        margin-left: 8%
    
     }
     .profilename{
        color: #06384e;
        font-size: 5vw;
        margin-top: 3%;
        margin-left: 22%;
        position: absolute;
        font-weight: bolder;
        width: 60%;
    
     }
     .profilephone{
        position: absolute;
        margin-top: 9%;
        margin-left: 22%;
        color: white;
        font-size: 2vw;
     }
    .send-button{
        position: absolute;
        width: 9vw;
        height: 9vw;
        font-size: 1.4vw;
        text-align: center;
        align-items: center;
        justify-content: center;
        border: 0px;
        background-color: #06384e;
        margin-top: 3%;
        margin-left: -10%;
        color: white;
    
    }
    .sendicon{
        width: 6.5vw;
        height: 6.5vw;
        display: flex;
        /* text-align: center;
        align-items: center;
        justify-content: center; */
        margin-top: -10%;
        margin-left: -75%;
        cursor: pointer;
    }
    
    .emojiicon{
     width: 6vw;
     height: 6vw; 
     margin-left: 3%;
     margin-top: 6%;
     cursor: pointer;
    }
    .emojiicon2{
        width: 6vw;
        height: 6vw; 
        margin-left: 1%;
        margin-top: 6%;
        cursor: pointer;
       }
    .fileicon{
        width: 6vw;
        height: 6vw; 
        margin-left: 2%;
        cursor: pointer;
    }
    .chatsentrycontainer{
        position: fixed;
        
        top: 33vh;
        left: -35%;
        width: 90%;
        height: 20vh;
    
    
        text-align: center;
        align-items: center;
        justify-content:center;;

        border-radius: 4vw;
        background-color: #d4eef8;
        box-shadow: 2px 2px 12px -2px black ;
    }
    .chatscontainerhead{
        font-size: 10vw;
        font-family: "Nunito sans", sans-serif;
        font-weight: bolder;
        color: #06384e;
        margin-top: 10%;
    }
    .chatscontainerinfo{
        font-size: 5vw;
        font-family: "Nunito sans", sans-serif;
        font-weight: bolder;
        color: #16AEE8;
    }
    
 }
