.adminactionbarcontainer{
    width: 4.9%;
    height: 100vh;
    position: absolute;
    background-color: #031218;
}
.icons{
    width: 3.5vw;
    height: 3.5vw;
    margin-bottom: 50%;
    margin-top: 40%;
    padding: 5%;
    cursor: pointer;
    margin-left: 7%;
}
/* .adminactionelements{
 
 width: 90%;
margin-left: 4%;
height: 10vh;
} */

.leftarrowselectorpath{
    height: 3vw;
    width: 3vw;
    position: fixed;
    bottom: 4.5vh;
    left: 1vw;
    margin-bottom: 0%;
    margin-top: 0%;
    margin-left: 0%;
    cursor: pointer;
}
@media screen and (max-width:412px) {
    .adminactionbarcontainer{
        z-index:100;
        width: 100%;
        height: auto;
        position: absolute;
        background-color: #031218;
        display:flex;
        z-index:0
    }
    .adminactionelements{
        margin-left:7%;
        margin-top:2%
    }
    .icons{
        width: 7vw;
        height: 7vw;
        margin-bottom: 50%;
        margin-top: 20%;
        padding: 5%;
        cursor: pointer;
        margin-left: 600%;
       
    }
    .newchatbtn{
        height: 10vw;
        width: 10vw;
        background-color: transparent;
        border: 0px;
        position: fixed;
        right:10px
    }
    
    .leftarrowselectorpath{
        height: 8vw;
        width: 8vw;
        position: fixed;
        left: 89vw;
        top: 3vw;
        cursor: pointer;
    }
    
}