.zopins{
  color: #16AEE8;
  font-size: 4vw;
  margin-left: 2%;
  margin-top: 0%;

  text-decoration: underline;
}
.createappbox{
    position: absolute;
    width: 83%;
    height: 100vh;
    margin-left: 17%;
 background-color: #05242f;
 animation: showapp .4s ease-in-out forwards;
}
@keyframes showapp {
    from{
      opacity: 0;
    }
    to{
     opacity: 1;
    }
    
}
.createinfo{
color: white;
font-size: 1.4vw;
font-family: "Nunito sans",sans-serif;
width: 95%;
height: auto;
}

.noappcreationmsg{
  width: 80%;
  height: auto;
  background-color: #0d4255;
  border-radius: .7vw;
  font-size: 2vw;
  color: white;
  padding: 3%;
  text-align: center;
  align-items: center;
  justify-content: center;
  margin-top: 10%;
  margin-left: 7%;

}
@media screen and (max-width:412px){
  .createappbox{
    padding: 1%;
    position: fixed;
    width: 90%;
    height: 20vh;
    box-shadow: 2px 2px 12px -2px black;
    border-radius: 5vw;
    top: 35vh;
    left:-13%;
 background-color: #d4eef8;
 display: block;
 animation: showapp .4s ease-in-out forwards;
}
@keyframes showapp {
    from{
      opacity: 0;
    }
    to{
     opacity: 1;
    }
    
}
.createinfo{
color: white;
font-size: 5vw;
font-family: "Nunito sans",sans-serif;
width: 95%;
height: auto;
}
.noappcreationmsg{
  width: 80%;
  height: 10vh;
  border-radius: 5vw;
  font-size: 4vw;
  color: #05242f;
  padding: 3%;
  display: flex;
  text-align: center;
  align-items: center;
  justify-content: center;
  margin-top: 1%;
  margin-left: 7%;
  background-color: transparent;
  font-weight: bold;

}
.creatingmessage{
  display: none;
}
}