.templatequestionviewer{
    width: 100%;
    padding: 3%;
    font-size: 1.4vw;
    color: black;
    height: auto;
    min-height: 5vh;
    background-color: rgb(216, 216, 216);
    border-radius: .2vw;
}
.templatequestion{
    font-size: 1.1vw;
    width: 96%;
    height: auto;
    background-color: white;
    color: black;
    border-radius: .2vw;
    padding: 2%;  
}
.templateanswer{
    margin-top: 1%;
}
.selected_domain_for_nodemaking{
    position: absolute;

    font-size: 2vw;
    width: auto;
    height: auto;
    padding:2px;
    border-radius: .4vw;
    display: flex;
    text-align: center;
    align-items: center;
    justify-content: center;
    margin-left: 22%;
    margin-top: 1vh;
    color: #16AEE8;

    background-color: white;
}

.domainbackicon{
    height: 2vw;
    width: 2vw;
    margin-top: 3%;
    cursor: pointer;
    z-index: 10000;
}

@media screen and (max-width:412px) {
    .mychatbots{
        margin-top: 10vh;
    }
    .removebtn{
      min-height: 4vh;
    }

    .templatequestionviewer{
        width: 100%;
        padding: 3%;
        font-size: 1.4vw;
        color: black;
        height: auto;
        min-height: 5vh;
        background-color: rgb(216, 216, 216);
        border-radius: .2vw;
    }
    .templatequestion{
        font-size: 1.1vw;
        width: 96%;
        height: auto;
        background-color: white;
        color: black;
        border-radius: .2vw;
        padding: 2%;  
    }
    .templateanswer{
        margin-top: 1%;
    }
    .selected_domain_for_nodemaking{
        position: absolute;
    
        font-size: 2vw;
        width: auto;
        height: auto;
        padding:2px;
        border-radius: .4vw;
        display: flex;
        text-align: center;
        align-items: center;
        justify-content: center;
        margin-left: 22%;
        margin-top: 1vh;
        color: #16AEE8;
    
        background-color: white;
    }
    
    .domainbackicon{
        height: 2vw;
        width: 2vw;
        margin-top: 3%;
        cursor: pointer;
        z-index: 10000;
    }
}