.morecontainer{
    margin-top: 1%;
    margin-left: 2%;
    font-size: 1.3vw;
    color: black;
    font-weight: bolder;
    
}
.morehead{
    color: white;
    /* background-color: #052b3a; */
    color: #052b3a;
}
.morecontainer>div{
    cursor: pointer;
    padding: 2%;
    margin-bottom: 1%;
}
.morebox{
    overflow: scroll;
    width: 30%;
    margin-top: 10%;
    height: 70vh;
    margin-left: 69%;
    border-radius: 0.4vw;
    margin-top: 7%;
    animation: showmorefeat .1s ease-in-out forwards;
}
@keyframes showmorefeat {
    from{
        height: 0vh;
    }
    to{
        height: 70vh;
    }
    
}

.nochats{
    position: absolute;
    font-size: 1.6vw;
    font-weight: bolder;
    width: 40%;
    height: auto;
    padding: 5%;
    background-color: #052b3a;
    color: white;
    border-radius: .7vw;
    display: flex;
    text-align: center;
    align-items: center;
    justify-content: center;
    margin-left: 30%;
    margin-top: 12%;
    animation: shownochats .4s ease-in-out forwards;
}
@keyframes shownochats {
    from{
      opacity: 0;
    }
    to{
        opacity: 1;
    }
    
}

.employeecontainer{
    margin-left: 5%;
}
.pemployee{
    cursor: pointer;
    padding: 1%;
    border-radius: .4vw;
    height: 2vw;
    width: 94%;
    color: #052b3a;
    font-family: "Nunito sans",sans-serif;
    font-size: 1.4vw;
}
.selectemployeebtn{
    /* position: fixed; */
    display: flex;
    text-align: center;
    align-items: center;
    justify-content: center;
    border-radius: .4vw;
    font-size: 1.4vw;
    color: white;
    background-color: #16AEE8;
    width: 93.5%;
    height: 2.5vw;
    padding: 1%;
    margin-top: 2%;
    /* margin-top: 18%; */
    cursor: pointer;
}
.customerassignmentinfo{
    position: absolute;
    font-size: 1.2vw;
    height: auto;
    width: auto;
    padding: 1%;
    background-color: #3ac7ff;
    border-radius: .4vw;
    margin-left: 3%;
    margin-top: -1%;
}
.backarrow{
    position:absolute;
    height:7vw;
    width:7vw;
    margin-top:3%;
    margin-left:.2%

}

@media screen and (max-width:412px){
    .morecontainer{
        margin-top: 1%;
        margin-left: 2%;
        font-size: 1.3vw;
        color: black;
        font-weight: bolder;
        
    }
    .morehead{
        color: white;
        /* background-color: #052b3a; */
        color: #052b3a;
    }
    .morecontainer>div{
        cursor: pointer;
        padding: 2%;
        margin-bottom: 1%;
    }
    .morebox{
        overflow: scroll;
        width: 30%;
        margin-top: 15%;
        margin-left: 69%;
        border-radius: 0.4vw;
        animation: showmorefeat .1s ease-in-out forwards;
        height:auto;
    }
    @keyframes showmorefeat {
        from{
            height: 0vh;
        }
        to{
            height: 70vh;
        }
        
    }
    
    .nochats{
        position: absolute;
        font-size: 1.6vw;
        font-weight: bolder;
        width: 40%;
        height: auto;
        padding: 5%;
        background-color: #052b3a;
        color: white;
        border-radius: .7vw;
        display: flex;
        text-align: center;
        align-items: center;
        justify-content: center;
        margin-left: 30%;
        margin-top: 12%;
        animation: shownochats .4s ease-in-out forwards;
    }
    @keyframes shownochats {
        from{
          opacity: 0;
        }
        to{
            opacity: 1;
        }
        
    }
    
    .employeecontainer{
        margin-left: 5%;
    }
    .pemployee{
        cursor: pointer;
        padding: 1%;
        border-radius: .4vw;
        height: auto;
        width: 94%;
        color: #052b3a;
        font-family: "Nunito sans",sans-serif;
        font-size: 2vw;
    }
    .selectemployeebtn{
        /* position: fixed; */
        display: flex;
        text-align: center;
        align-items: center;
        justify-content: center;
        border-radius: .4vw;
        font-size: 2vw;
        color: white;
        background-color: #16AEE8;
        width: 93.5%;
        height: 2.5vw;
        padding: 1%;
        margin-top: 2%;
        /* margin-top: 18%; */
        cursor: pointer;
    }
    .customerassignmentinfo{
        position: absolute;
        font-size: 1.2vw;
        height: 1.5vw;
        width: auto;
        padding: 1%;
        background-color: #3ac7ff;
        border-radius: .4vw;
    }
    .backarrow{
        z-index: 100;
        position:fixed;
        height:7vw;
        width:7vw;
        margin-top:3%;
        margin-left:.2%
    
    }
}