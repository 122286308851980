.maindashcontainer{
    animation: showdash .5s ease-in-out forwards;
}
@keyframes showdash {
    from{
   opacity: 0;
   transform: translateX(-5vw);
    }
    to{
    opacity: 1;
    transform: translateX(0vw);
    }
    
}
.dashboardhead{
    position: fixed;
    position: absolute;
    z-index: -1;
    font-size: 2.6vw;
    color: #06435b;
    font-weight: bolder;
margin-left: 6%;

}
.dashnav{
    position: fixed;
    border-radius: .4vw;
    margin-top: 6%;
    padding: 1%;
    margin-left: 6%;
    position: absolute;
    width: 10%;
    height: 80vh;
    background-color: #3c7083;
}
.dele{
    cursor: pointer;
    font-weight: 800;
    margin-top: 15%;
    border-radius: .4vw;
    display: flex;
    text-align: center;
    align-items: center;
    justify-content: center;
  height: 4vw;
  width: 100%;
  background-color: #16AEE8;
  color: white;
  box-shadow: 2px 2px 12px -3px black;
}

/* new templates */

.newtemplates{
    display: flex;
    margin-left: 20%;
    position: absolute;
    width: 80%;
    height: 100vh;
    animation: shownewtemp .5s ease-in-out forwards;
    
}
@keyframes shownewtemp {
    from{
   opacity: 0;
 
    }
    to{
    opacity: 1;

    }
    
}
.templategenerator{
    width: 70%;
    height: 100vh;
}
.templateviewer{
    margin: 2%;
    margin-top: 3%;
    width: 30%;
    height: 90vh;
    border-radius: .7vw;
  box-shadow: 2px 2px 12px -3px black;
}

@media screen and (max-width:412px) {
    .maindashcontainer{
        animation: showdash .5s ease-in-out forwards;
    }
    @keyframes showdash {
        from{
       opacity: 0;
       transform: translateX(-5vw);
        }
        to{
        opacity: 1;
        transform: translateX(0vw);
        }
        
    }


    
    /* new templates */
    
    .newtemplates{
        display: flex;
        margin-left: 20%;
        position: absolute;
        width: 80%;
        height: 100vh;
        animation: shownewtemp .5s ease-in-out forwards;
        
    }
    @keyframes shownewtemp {
        from{
       opacity: 0;
     
        }
        to{
        opacity: 1;
    
        }
        
    }
    .templategenerator{
        width: 70%;
        height: 100vh;
    }
    .templateviewer{
        margin: 2%;
        margin-top: 3%;
        width: 30%;
        height: 90vh;
        border-radius: .7vw;
      box-shadow: 2px 2px 12px -3px black;
    }
    
}