.drawerbody{
    position: absolute;
    
}
.drawerhead{
  color: #074158;
  font-size: 2vw;
  font-family: "Nunito sans",sans-serif;
  font-weight: bolder;
}
.myprofilename{
    
    width: 200%;
    margin-left: -100%;
    height: 3vw;
    font-size: 2vw;
    background-color: #16AEE8;
    font-family: "Nunito sans",sans-serif;
}
.myprofilename:hover{
    background-color: #093d52;
}
.myprofileicon{
margin-top: 100%;
width: 6vw;
height: 6vw;
background-color: #074158;
color: white;
font-size: 4vw;
font-weight: bolder;
border-radius: 50%;
display: flex;
text-align: center;
align-items: center;
justify-content: center;
}

/* popup */

.popup-container {
    position: relative;
  }
  
  .popup-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5); /* Semi-transparent black overlay */
    backdrop-filter: blur(9px);
    z-index: 1000;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .popup-content {
    overflow: scroll;
    font-size: 2vw;
  
    border: 4px solid #16AEE8;
    height: 30vw;
    width: 50%;
    background-color: white;
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
  }
  
  .close-btn {
    position: absolute;
    top: 10px;
    right: 10px;
    background-color: transparent;
    border: none;
    cursor: pointer;
    color:white;
    font-size: 2vw;
  }
  .imagetobesent{
    width: 70%;
    height: 25vw;
  }
  .docsend{
    cursor: pointer;
    margin-top: -5%;
    margin-left: 38%;
    position: fixed;
    font-size: 2vw;
    padding: 1%;
    border-radius: .4vw;
    width: 10%;
    height: 3vw;
text-align: center;
align-items: center;
justify-content: center;
    background-color: transparent;
    backdrop-filter: blur(10px);
    color: #16AEE8;
    box-shadow: 2px 2px 12px -5px black;
  }