
.templatesave{
    width: 30%;
    height: 3vw;
    background-color: #032939;
    color: white;
    font-size: 2vw;
    display: flex;
    text-align: center;
    align-items: center;
    justify-content: center;
    margin-top: 1%;
    margin-left: 67%;
    border-radius: .4vw;
    cursor: pointer;
}
.templatemaincontent{
    padding: 2%;
    height: 15vw;
    width: 93%;
    border-radius: .4vw;
    background-color: #d6edf7;
}
.templatemaininputs{
    margin-top: 2%;
}
.templatelabels{
    font-size: 1.5vw;
    font-family: "Nunuto sans ",sans-serif;
    color: #032939;
}
.templateinputs{
    all: unset;
    width: 90%;
    height: 2vw;
    padding: 1%;
    border-radius: .4vw;
    color: white;
    background-color: #032939;
}
.templateinputs::placeholder{
    color: white;
}

.templateheaderbox{
    margin-top: 2%;
    padding: 2%;
    height: auto;
    width: 93%;
    border-radius: .4vw;
    background-color: #d6edf7;
}
.headerchooser{
    display: flex;
}
.headerswitch{
    margin-left: 20%;
}
.headermakerbox{
    margin-top: 2%;
}
.headervariable{
    margin-top: 1%;
    padding: 1%;
    width: 90%;
    height: 8vw;
    background-color: #638fa1;
    border-radius: .4vw;
    color: white;
    font-size: 1.1vw;
}
.variable{
    margin-top: 2%;
    width: 97.3%;
    background-color: transparent;
    border: 1px solid white;
}

.textbox{
    margin-top: 2%;
    padding: 2%;
    height: auto;
    width: 93%;
    border-radius: .4vw;
    background-color: #d6edf7;
}
.messageinput{
    height: 7vw;
}
.messagevariablebox{
    margin-top: 1%;
    padding: 1%;
    width: 90%;
    height: 30vw;
    background-color: #638fa1;
    border-radius: .4vw;
    color: white;
    font-size: 1.1vw;
}
.variablebox{
    display: flex;
    margin-top: 2%;
}
.messagevar{
height: 3vw;
width: 3vw;
background-color: #032939;
color: white;
display: flex;
text-align: center;
align-items: center;
justify-content: center;
margin: 1%;
border-radius: .4vw;
}
.messagevariable{
    width: 290%;
    height: 3vw;
    color: white;
    border-radius: .4vw;

}
.messagevariable::placeholder{
    color: white;
}
.templatebuttonsbox{
    padding: 2%;
    margin-top: 2%;
    height: auto;
    width: 93%;
    border-radius: .4vw;
    background-color: #d6edf7;
}
.buttonsbox{
    margin-left: -18.5%;
    margin-top: 7%;
    width: 30%;
    height: 20vw;
}
.templatebuttons{
    cursor: pointer;
    display: flex;
    width: 100%;
    height: 3vw;
    background-color: #032939;
    font-size: 1.5vw;
    text-align: center;
    align-items: center;
    justify-content: center;
    margin-top: 1%;
    color: white;
    border-radius: .4vw;

}
.addedbuttons{
    border-radius: .4vw;
   overflow: scroll;
   margin-top: 7%;
   margin-left: 9%;
    width: 60%;
    height: 20vw;
    background-color: #638fa1;
}
.adjustbox{
    display: flex;
    padding: 2%;
    margin-top: 2%;
}
.adjustablebutton{
width: 88%;
height: 3vw;
background-color: #16AEE8;
border-radius: .4vw;
color: white;
font-size: 1.5vw;
display: flex;
text-align: center;
align-items: center;
justify-content: center;
box-shadow: 2px 2px 12px -3px black;
}
.removebutton{
    height: 3vw;
    width: 3vw;
    font-size: 1.5vw;
    display: flex;
text-align: center;
align-items: center;
justify-content: center;
margin-left: 3%;
border-radius: .4vw;
box-shadow: 2px 2px 12px -3px black;

}

/* template viewer */

.templateviewer{
    background-color: #032939;
    margin: 2%;
    margin-top: 3%;
    width: 30%;
    height: 90vh;
    margin-left: 70%;
    border-radius: .7vw;
  box-shadow: 2px 2px 12px -3px black;
}

.template{
    margin-top: 20%;
    width: 90%;
    margin-left: 2%;
   height: auto;
    border-radius: .7vw;
}
.templatehead{
    font-size: 1.5vw;
    font-weight: bolder;
}
.templatetext{
    background-color: white;
    border-radius: .7vw;
    padding: 3%;
    border: 2px solid black;
    width: 100%;
    height: auto;
    color: black;
    font-size: 1.2vw;
    font-family: "Ninito sans" , sans-serif;
    font-weight: 400;
}
.buttons{
    width: 106.5%;
    height: 3vw;
    display: flex;
    text-align: center;
    align-items: center;
    justify-content: center;
    font-size: 2vw;
    color: white;
    background-color: #16AEE8;
    border-radius: .4vw;
    margin-top: 1%;
}

.templatesentpopup{
    width: 30%;
    height: 4vw;
    font-size: 2vw;
    color: white;
    display: flex;
    text-align: center;
    align-items: center;
    justify-content: center;
    border: 2px solid #032939;
    border-radius: .4vw;
    background-color: #032939;
    padding: 2%;
    border: 2px solid white;
}

@media screen and (max-width:412px){
    
.templatesave{
    width: 40%;
    height: 5vw;
    background-color: #032939;
    color: white;
    font-size: 3vw;
    display: flex;
    text-align: center;
    align-items: center;
    justify-content: center;
    margin-top: 1%;
    margin-left: 60%;
    border-radius: .4vw;
    cursor: pointer;
}
.templatemaincontent{
    padding: 2%;
    height: auto;
    width: 93%;
    border-radius: .4vw;
    background-color: #d6edf7;
    margin-top:0%
}
.templatemaininputs{
    margin-top: 2%;
}
.templatelabels{
    font-size: 4vw;
    font-family: "Nunuto sans ",sans-serif;
    color: #032939;
}
.templateinputs{
    all: unset;
    width: 90%;
    height: 2vw;
    padding: 3%;
    border-radius: .4vw;
    color: white;
    
    background-color: #032939;
    font-size:2vw
}
.selectinput{
    font-size:2vw;
    height:1vh
}
.templateinputs::placeholder{
    color: white;
    font-size:2vw;
}


.templateheaderbox{
    margin-top: 2%;
    padding: 2%;
    height: auto;
    width: 93%;
    border-radius: .4vw;
    background-color: #d6edf7;
}
.headerchooser{
    display: flex;
}
.headerswitch{
    margin-left: 20%;
    margin-top:10%
}
.headermakerbox{
    margin-top: 2%;
}
.headervariable{
    margin-top: 1%;
    padding: 1%;
    width: 90%;
    height: auto;
    background-color: #638fa1;
    border-radius: .4vw;
    color: white;
    font-size: 2vw;
}
.variable{
    margin-top: 2%;
    width: 90%;
    background-color: transparent;
    border: 1px solid white;
}

.textbox{
    margin-top: 2%;
    padding: 2%;
    height: auto;
    width: 93%;
    border-radius: .4vw;
    background-color: #d6edf7;
}
.messageinput{
    height: 7vw;
}
.messagevariablebox{
    margin-top: 1%;
    padding: 1%;
    width: 90%;
    height: auto;
    background-color: #638fa1;
    border-radius: .4vw;
    color: white;
    font-size: 2vw;
}
.variablebox{
    display: flex;
    margin-top: 2%;
}
.messagevar{
height: 4vw;
width: 4vw;
background-color: #032939;
color: white;
display: flex;
text-align: center;
align-items: center;
justify-content: center;
margin: 1%;
border-radius: .4vw;
}
.messagevariable{
    width: 205%;
    height: 4vw;
    color: white;
    border-radius: .4vw;

}
.messagevariable::placeholder{
    color: white;
}
.templatebuttonsbox{
    padding: 2%;
    margin-top: 2%;
    height: auto;
    width: 93%;
    border-radius: .4vw;
    background-color: #d6edf7;
    margin-left:0%
}
.buttonsbox{
    margin-left: -18.5%;
    margin-top: 12%;
    width: 30%;
    height: 20vw;
    margin-left:-33%
}
.templatebuttons{
    cursor: pointer;
    display: flex;
    width: 100%;
    height: 3vw;
    background-color: #032939;
    font-size: 1.5vw;
    text-align: center;
    align-items: center;
    justify-content: center;
    margin-top: 1%;
    color: white;
    border-radius: .4vw;

}
.addedbuttons{
    border-radius: .4vw;
   overflow: scroll;
   margin-top: 10%;
   margin-left: 9%;
    width: 60%;
    height: 20vw;
    background-color: #638fa1;
}
.adjustbox{
    display: flex;
    padding: 2%;
    margin-top: 2%;
}
.adjustablebutton{
width: 88%;
height: 3vw;
background-color: #16AEE8;
border-radius: .4vw;
color: white;
font-size: 1.5vw;
display: flex;
text-align: center;
align-items: center;
justify-content: center;
box-shadow: 2px 2px 12px -3px black;
}
.removebutton{
    height: 3vw;
    width: 3vw;
    font-size: 1.5vw;
    display: flex;
text-align: center;
align-items: center;
justify-content: center;
margin-left: 3%;
border-radius: .4vw;
box-shadow: 2px 2px 12px -3px black;

}

/* template viewer */

.templateviewer{
    display:none;
    background-color: #032939;
    margin: 2%;
    margin-top: 40%;
    width: 30%;
    height: 50vh;
    margin-left: 70%;
    border-radius: .7vw;
  box-shadow: 2px 2px 12px -3px black;
}

.template{
    margin-top: 30%;
    width: 86%;
    margin-left: 2%;
   height: auto;
    border-radius: .7vw;
}
.templatehead{
    font-size: 1.5vw;
    font-weight: bolder;
}
.templatetext{
    background-color: white;
    border-radius: .7vw;
    padding: 3%;
    border: 2px solid black;
    width: 100%;
    height: auto;
    color: black;
    font-size: 1.5vw;
    font-family: "Ninito sans" , sans-serif;
    font-weight: 400;
}
.buttons{
    width: 106.5%;
    height: 3vw;
    display: flex;
    text-align: center;
    align-items: center;
    justify-content: center;
    font-size: 2vw;
    color: white;
    background-color: #16AEE8;
    border-radius: .4vw;
    margin-top: 1%;
    margin-left:1%;
}

.templatesentpopup{
    width: 30%;
    height: 4vw;
    font-size: 2vw;
    color: white;
    display: flex;
    text-align: center;
    align-items: center;
    justify-content: center;
    border: 2px solid #032939;
    border-radius: .4vw;
    background-color: #032939;
    padding: 2%;
    border: 2px solid white;
}
.previewtemplate{
    position:absolute;
    width:40%;
    height:2.3vh;
    display:flex;
    text-align:center;
    align-items:center;
    justify-content:center;
    background-color:#16AEE8;
    color:white;
    font-size:3vw;
    margin-top: 1%;
    border-radius: 1vw;

}
}
