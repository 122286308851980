
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

::-webkit-scrollbar {
  width: 5px;
  height: 3px;
  
}

/* Track */
::-webkit-scrollbar-track {
  background: #498cc0;
background-color: transparent;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #2b526f;
  background-color: transparent;
  
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555;
}

/* Customizing scrollbar on specific elements */
.element-with-custom-scrollbar::-webkit-scrollbar {
  width: 5px;
  height: 5px;
}

.element-with-custom-scrollbar::-webkit-scrollbar-thumb {
  background: linear-gradient(to right, #ff9a9e, #fecfef);
  border-radius: 5px;
}