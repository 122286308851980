.banner{

    text-align: center;
    justify-content: center;
    width: 100%;
    height: 30vw;

}
.bck-box2{
    position: absolute;
    width: 100%;
    height: 600vh;
    background-color: #142c43;
    z-index: 1;
}
.bck-box3{
    position: absolute;
    width: 100%;
    height: 250vw;
    background-color: #142c43;
    z-index: 1;
}
.bannerhead{
    font-size: 6vw;
    font-weight: bolder;
    color: #0a3c63;
}
.subhead{
    font-size: 1.5vw;
    color: #003c71;
    margin-top: -1%;
}
.bannerdisc{
    font-size: 3vw;
    font-weight: bolder;
    font-family: "Nunito sans",sans-serif;
    color: #0e4d7d;
}
.backgroundimg{
    opacity: .5;
    margin-left: 20%;
    position: absolute;
    margin-top: -20%;
    z-index: -1;
}

.car-qr{
    position: absolute;
    margin-top: 15%;
    width: 100%;
    height: 55vw;
    background-image: url("https://mypin.in/static/images/shapes.svg");
    animation: jellypop 2s infinite ease-in-out forwards;
}
@keyframes jellypop {
    0%{
          transform: translateY(-10px);
    }
    50%{
transform: translateY(-5px);
    }
    100%{
transform: translateY(-10px);
    }
}
.car-main{
    width: 100%;
    height: auto;
    margin-top: 20%;
    position: absolute;
}

.car-qr-img{
    margin-left: 55%;
    position: absolute;
    margin-top: 8%;
    height: 40vw;
    width:40%;

}
.car-qr-info{
    position: absolute;
    width: 44%;
    height: 30vw;
    margin-left: 10%;
    margin-top: 14%;
    font-family:'Nunito Sans',sans-serif;
}
.car-parking-head{
    font-size: 5vw;
    font-weight: bolder;
    color: #003c71;
    font-family:'Nunito Sans',sans-serif;
}
.head22{
    font-size: 2vw;
    margin-bottom: 5%;
    font-weight: bolder;
    font-family:'Nunito Sans',sans-serif;
}

.more-info2{
    color: #003c71;
    width: 85%;
    font-size: 2vw;
    margin-top: -5%;
    margin-left: 0%;
}
.car-qr-btn{
    border: 0px;
    background-color: #16AEE8;
    color: white;
    width: 50%;
    height: 6vw;
    font-size: 1.5vw;
    border-radius: 1vw;
    margin-top: 10%;
}
.car-qr-btn:hover{
background-color: #0b4170(130, 35, 35);
transition-duration: .7s;
}
.hoverit:hover{
    background-color: #063c6b;
}
.how-container{
    width: 100%;
    height: auto;
    margin-top: 95%;
    position: absolute;
}

.solution-box{
    text-align: end;
    align-items: end;
    justify-content: end;
   
    width: 50%;
    margin-top: 10%;
    margin-left: 40%;
    height: auto;
    
}
.solution1{
    font-size: 5vw;
    color: #003c71;
    font-weight: bolder;
}
.phone-img{
    width: 40%;
    height:35vw;
    margin-left: 5%;
    position: absolute;
}

.solution2{
    font-weight: bold;

    font-size: 2vw;
    color: #16AEE8;
    font-family:'Nunito Sans',sans-serif;
    
}
.solution3{
   

    font-size: 2vw;
    color:#003c71;
    font-family:'Nunito Sans',sans-serif;
}

.whychoosebox{
    width: 100%;
    height: 40vw;
    position: absolute;
    margin-top: 70%;
}
.whyhead{
    font-size: 4vw;
    font-weight: bolder;
    font-family: "Nunito sans",sans-serif;
    padding: 2%;
    display: flex;
    text-align: center;
    align-items: center;
    justify-content: center;
    color: #16AEE8;
}
.whybox{
    display: flex;
    margin-top: 3%;
}
.whyboxes{

    width: 20%;
    height: 30vw;
    margin-left: 4%;
    border-radius: 1vw;

}
.whybox1{
    z-index: 2;
    overflow: hidden;
    width: 100%;
    
    height:20vw;
    border-radius: .7vw;
    box-shadow: 2px 2px 10px -3px black;

}
.whybox2{
    display: flex;
    text-align: center;
    align-items: center;
    justify-content: center;
    z-index: 10;
    font-size: 1.7vw;
    color: #0a5979;
    width: 90%;
    height:7vw;
    background-color: transparent;
    backdrop-filter: blur(6px);
    margin-top: -40%;
    margin-left: 5%;
    border-radius: .7vw;
    font-weight: bolder;
    box-shadow: 2px 2px 10px -3px rgb(54, 54, 54);

}
.whyimg{
    z-index: 2;
    width: 100%;
    height: 20vw;
}
.whyboxestitle{
    display: flex;
    text-align: center;
    align-items: center;
    justify-content: center;
    font-size: 2vw;
    width: 90%;
    height: auto;
    border: 1px solid black;
    margin-left: 5%;
    border-radius: .5vw;

}
.whyimg:hover{
    scale: 1.1;
    transition-duration: .7s;
}
/* footer */

.footer{
    position: absolute;
    margin-top: 20%;
    width: 100%;
    height: auto;
    border-top: 3vw solid #16AEE8;
    background-color: #011b25;
}
.f-logo{
    width: 10vw;
    height: 10vw;
    margin-left: 8%;
    margin-top: 5%;
    position: absolute;
}
.footer-solutions{
position: absolute;
 margin-left: 40%;
 margin-top: 5%;
 color: white;
 cursor: pointer;
    font-family: 'Nunito Sans',sans-serif;
}
.footer-seo{
    position: absolute;
    margin-left: 40%;
    margin-top: 30%;
    color: white;
    font-family: 'Nunito Sans',sans-serif;
}
.sol-head{
    font-size: 1.5vw;
    margin-bottom: 10%;
    font-weight: 400;
    color: white;
    font-family:'Nunito Sans',sans-serif;
}
.sols{
    font-size: 1.2vw;
    margin-bottom: 10%;
    font-weight: lighter;
    color: white;
    font-family:'Nunito Sans',sans-serif;
}
.footer-resources{
    position: absolute;
    margin-left: 70%;
    margin-top: 5%;
    color: white;
    font-family: 'Nunito Sans',sans-serif;
}
.resources-head{
    margin-bottom: 10%;
    font-size: 1.5vw;
    margin-bottom: 10%;
    font-weight: 400;
    color: white;
    font-family: 'Nunito Sans',sans-serif;
}
.resources{
    font-size: 1.2vw;
    margin-bottom: 15%;
    font-weight: lighter;
    color: white;
    font-family: 'Nunito Sans',sans-serif;

}
.profile{
    margin-top: 16%;
    margin-left: 7.5%;
    position: absolute;
    font-weight: lighter;
    font-family:'Nunito Sans',sans-serif;
    color: #16AEE8;
}

.bck-img{
    display: flex;
    scale: 3.8;
width: 40%;
height: 50vw;
margin-top: 50%;
position: absolute;
animation: jellypop 2s infinite ease-in-out forwards;
}

.reviewbox{
    width: 100%;
    height: 40vw;
    position: absolute;
    margin-top: 120%;
    display: flex;
}
.reviewhead{
    font-size: 4vw;
    width: 120%;
    margin-left: 8%;
    margin-top: 10%;
    color: #16AEE8;
    font-weight: bolder;
}

.pricecontainer2{
    width: 90%;
    height:40vw;
  
   
    position: absolute;
    margin-top: 145%;
    margin-left: 5%;
    display: flex;
    overflow: hidden;
}
.tagshow{
    margin-top: 2.5%;
    width: 50%;
    height: 25vw;
    margin-left: 50%;
   
    position: absolute;

}
.pricetagimg{
    width: 90%;
    height: 25vw;
    margin-left: 10%;
    margin-top: 5%;
    box-shadow: 5px 5px 12px -5px black;
    position: absolute;
}
.pricebox{
    width: 50%;
    height: 35vw;
    margin-left: 0%;
    margin-top: 4%;
    position: absolute;
}

.pricehead{
    font-weight: bolder;
    font-family: "Nunito sans",sans-serif;
    padding: 5%;
    font-size: 4vw;
    color: #006E99;
}
.priceinfo{
    font-weight: bolder;
    font-family: "Nunito sans",sans-serif;
    font-size: 2vw;
    color: #16AEE8;
 margin-left: 5%;
 margin-top: -7%;
    
}
.pricedisc{
    font-family: "Nunito sans",sans-serif;
    font-size: 2vw;
    color: #006E99;
 margin-left: 5%;

}
.ownnowbtn{
    width: 60%;
    height: 5.7vw;
    display: flex;
    text-align: end;
    align-items: center;
    justify-content: center;
    color: white;
    font-size: 2vw;
    font-family: "Nunito sans",sans-serif;

    border-radius: 1vw;
    background-color: #16AEE8;
    margin-left: 5%;
    margin-top: 3%;
    cursor: pointer;
}
/* door */

.doorpricebox{
    width: 90%;
    height: 60vw;
    position: absolute;
    margin-top: 140%;
    margin-left: 5%;
    display: flex;
    overflow: hidden;
}
.doortagimg{
   margin-left: 160%;
   margin-top: 15%;
    width: 110%;
    height: 50vw;
    box-shadow: 2px 2px 10px 0px black;
}
.doorpriceinfobox{
    width: 40%;
    height: 40vw;

    position: absolute;
    margin-left: 3%;
    margin-top: 17%;
}
.doorphead{
    padding: 5%;
    font-size: 4vw;
    color: #16AEE8;
    font-weight: bolder;
    font-family: "Nunito sans ", sans-serif;
}
.dprice{
    font-size: 2vw;
    color: #16AEE8;
    margin-top: 0%;
    font-weight: bolder;
    margin-left: 5%;
}
.doorpdisc{
    font-size: 1.8vw;
    color: #16AEE8;
    margin-left: 5%;
    font-weight: bold;

}

.margin1{
    display: block;
}
.margin2{
    display: none;
}
.dooragshow{
    
    height: 50vw;
}

@media screen and (min-width:430px) and (max-width:996px){
  .bck-box2{
    position: absolute;
    width: 100%;
    height: 100vh;
    background-color: #142c43;
    z-index: 1;

  }
    .banner{

        text-align: center;
        justify-content: center;
        width: 100%;
        height: 30vw;
    
    }
    .bannerhead{
        font-size: 6vw;
        font-weight: bolder;
        color: #0a3c63;
    }
    .subhead{
        font-size: 1.5vw;
        color: #003c71;
        margin-top: -1%;
    }
    .bannerdisc{
        font-size: 3vw;
        font-weight: bolder;
        font-family: "Nunito sans",sans-serif;
        color: #0e4d7d;
    }
    .backgroundimg{
        opacity: .5;
        margin-left: 20%;
        position: absolute;
        margin-top: -20%;
        z-index: -1;
    }
    .bck-img{
        display: flex;
        scale: 3.8;
    width: 40%;
    height: 50vw;
    margin-top: 40%;
    animation: jellypop 2s infinite ease-in-out forwards;
    }

}


@media screen and (max-width:430px){
    .bck-box2{
        position: absolute;
        width: 100%;
        height: 320vh;
        background-color: #142c43;
        z-index: 1;
    }
    .banner{

        text-align: center;
        justify-content: center;
        width: 100%;
        height: 30vw;
        margin-top: 40%;
    
    }
    .bannerhead{
        font-size: 20vw;
        font-weight: bolder;
        color: #0a3c63;
    }
    .subhead{
        font-size: 5vw;
        color: #003c71;
        margin-top: -1%;
    }
    .bannerdisc{
        font-size: 8vw;
        font-weight: bolder;
        font-family: "Nunito sans",sans-serif;
        color: #0e4d7d;
    }
    .backgroundimg{
        display: none;
        width: 50%;
        opacity: .5;
        margin-left: 20%;
        position: absolute;
        margin-top: -20%;
        z-index: -1;
    }
   .ip{
    width: 70vw;
   }
    .car-qr{
  
        position: absolute;
        margin-top: 19%;
        width: 100%;
        height: 100vw;
        margin-left: 0%;

        animation: jellypop 2s infinite ease-in-out forwards;
    }
    .bck-img{
        display: flex;
        scale: 3.8;
    width: 40%;
    height: 50vw;
    margin-top: 70%;
    animation: jellypop 5s infinite ease-in-out forwards;
    

    }
    @keyframes jellypop {
        0%{
              transform: translateY(-10px);
        }
        50%{
    transform: translateY(-5px);
        }
        100%{
    transform: translateY(-10px);
        }
    }
    .car-main{
        width: 100%;
        height: auto;
        margin-top: 40%;
        position: absolute;
    }
    
    .car-qr-img{
        margin-left: 55%;
        position: absolute;
        margin-top: 8%;
        height: 40vw;
        width:40%;
        display: none;
    
    }

    .car-qr-info{
        position: absolute;
       
        width: 90%;
        height: 30vw;
        margin-left: 10%;
        margin-top: 14%;
        font-family:'Nunito Sans',sans-serif;
    }
    .car-parking-info{
        /* text-align: center;
        align-items: center;
        justify-content: center; */
        margin-left: -8%;
    }
    .more-info2{
        width: 100%;
        font-size: 5.5vw;
        margin-top: -5%;
        color: #003c71;
        font-weight: bolder;
      
    }
    .car-parking-head{
        /* text-align: center;
        align-items: center;
        justify-content: center; */
        font-size: 14vw;
        margin-left: -10%;
        font-weight: bolder;
        color: #003c71;
        font-family:'Nunito Sans',sans-serif;
    }
    .head22{
        font-size: 5.5vw;
        font-weight: bolder;
        font-family:'Nunito Sans',sans-serif;
    }
    .car-qr-btn{
        border: 0px;
        background-color: #16AEE8;
        color: white;
        width: 70%;
        height: 15vw;
        font-size: 5vw;
        font-weight: bolder;
        border-radius: 1vw;
        margin-left: -7%;
       
    }
    .car-qr-btn:hover{
    background-color: #0b4170(130, 35, 35);
    transition-duration: .7s;
    }
    .hoverit:hover{
        background-color: #05569c;
    }
    .how-container{
        width: 100%;
        height: 240vw;
        margin-top: 190%;
        position: absolute;
    }

    .solution-box{
        text-align: end;
        align-items: end;
        justify-content: end;
        
        width: 100%;
        margin-top: 110%;
        height: 70vw;
        margin-left: -4%;
        
    }
    .solution1{
        font-size: 12vw;
        color: #003c71;
        font-weight: bolder;
    }
    .phone-img{
        width: 90%;
        height:100vw;
        margin-left: 5%;
        position: absolute;
    }
   
    .solution2{
        font-weight: bolder;
       
        font-size: 5vw;
        color: #16AEE8;
        font-family:'Nunito Sans',sans-serif;
        
    }
    .solution3{
        font-weight: bold;
    
        font-size: 5vw;
        color:#003c71;
        font-family:'Nunito Sans',sans-serif;
    }

    .whychoosebox{
        width: 100%;
        height: 40vw;
        position: absolute;
        margin-top: 220%;
    }
    .whyhead{
        font-size: 9vw;
        font-weight: bolder;
        font-family: "Nunito sans",sans-serif;
        padding: 2%;
     
        text-align: center;
        align-items: center;
        justify-content: center;
    }
    .whybox{
        display: inline;
        margin-top: 8%;

    }
    .whyboxes{
    
        width: 80%;
        height: 70vw;
        margin-left: 10%;
        margin-top: 5%;
        border-radius: 1vw;
    
    }
    .whybox1{
        z-index: 2;
        overflow: hidden;
        width: 100%;
        height:65vw;
        border-radius: 2vw;
        box-shadow: 2px 2px 10px -3px rgb(54, 54, 54);
    
    }
    .whybox2{
        font-weight: bolder;
        display: flex;
        text-align: center;
        align-items: center;
        justify-content: center;
        z-index: 10;
        font-size: 5vw;
        color: #16AEE8;
        width: 90%;
        margin-left: 5%;
        height:15vw;
        margin-top: -20%;
        background-color: transparent;
        backdrop-filter: blur(5px);
        border-radius: .7vw;
        box-shadow: 2px 2px 10px -3px rgb(54, 54, 54);
    
    }
    .whyimg{
        z-index: 2;
        width: 100%;
        height: 65vw;
        border-radius: 1vw;
    }
    .whyboxestitle{
        display: flex;
        text-align: center;
        align-items: center;
        justify-content: center;
        font-size: 2vw;
        width: 90%;
        height: auto;
        border: 1px solid black;
        margin-left: 5%;
        border-radius: .5vw;
    
    }
    .whyimg:hover{
        scale: 1.1;
        transition-duration: .7s;
    }
    .backgroundimg{
        display: block;
        margin-left: -16%;
        scale: .45;
    }
    /* footer */
    .footer-res{
        margin-top: 300%;
    }
    .footer{
        position: absolute;
        /* margin-top: 430%; */
        width: 100%;
        height: auto;
    }
    .f-logo{
        width: 15vw;
        height: 15vw;
        margin-left: 8%;
        margin-top: 5%;
        position: absolute;
    }
    .footer-solutions{
    position: absolute;
     margin-left: 40%;
     margin-top: 5%;
     color: white;
        font-family: 'Nunito Sans',sans-serif;
    }
    .sol-head{
        font-size: 4vw;
        margin-bottom: 10%;
        font-weight: 400;
        color: white;
        font-family:'Nunito Sans',sans-serif;
    }
    .sols{
        font-size: 2.5vw;
        margin-bottom: 10%;
        font-weight: lighter;
        color: white;
        font-family:'Nunito Sans',sans-serif;
    }
    .footer-resources{
        position: absolute;
        margin-left: 40%;
        margin-top: 60%;
        color: white;
        font-family: 'Nunito Sans',sans-serif;
    }
    .footer-seo{
        position: absolute;
        margin-left: 40%;
        margin-top: 112%;
        color: white;
        font-family: 'Nunito Sans',sans-serif;
    }
    .resources-head{
        margin-bottom: 10%;
        font-size: 4vw;
        margin-bottom: 10%;
        font-weight: 400;
        color: white;
        font-family: 'Nunito Sans',sans-serif;
    }
    .resources{
        font-size: 2.4vw;
        margin-bottom: 15%;
        font-weight: lighter;
        color: white;
        font-family: 'Nunito Sans',sans-serif;
    
    }
    .profile{
        margin-top: 20%;
        margin-left: 5%;
        position: absolute;
        font-weight: lighter;
        font-family:'Nunito Sans',sans-serif;
        color: white;
        font-size: 2.4vw;
    }
    .reviewbox{
        display: inline;
        width: 100%;
        height: 40vw;
        margin-top: 550%;
    }
    .reviewhead{
        display: flex;
        text-align: center;
        align-items: center;
        justify-content: center;
        font-size: 8vw;
        width: 90%;
        margin-left: 8%;
        margin-top: 10%;
        color: #0a4c7e;
        font-weight: bolder;
        margin-bottom: 5%;
    }

    .pricecontainer2{
        width: 90%;
        height: 150vw;
 
        box-shadow: 0;
        position: absolute;
        margin-top: 390%;
        margin-left: 5%;
    display: inline-block;
        overflow: hidden;
    }
    .tagshow{
        margin-top: 2.5%;
        width: 90%;
        height: 40vw;
       /* margin-left: 5%; */
       margin-left: -4.4%;
        position: absolute;
    
    }
    .pricetagimg{
        width: 100%;
        height: 50vw;
        /* margin-left: 10%; */
        margin-top: 5%;
        box-shadow: 5px 5px 12px -5px black;
        position: absolute;
    }
    .pricebox{
        width: 115%;
        height: 60vw;
       margin-left: -5%;
       margin-top: 68%;
       background-color: white;
        position: absolute;
    }
    
    .pricehead{
        padding: 5%;
        font-size: 14vw;
 
        color: #006E99;
        font-weight: bolder;
    }
    .priceinfo{
      
        font-size: 6vw;
        color: #16AEE8;
     margin-left: 5%;
     margin-top: -7%;
     font-weight: bolder;
        
    }
    .pricedisc{
        
        font-size: 5vw;
        color: #006E99;
  width: 90%;
  margin-left: 5%;
     font-weight: bolder;
    
    }
    .ownnowbtn{
        width: 70%;
        height: 14vw;
        display: flex;
        text-align: end;
        align-items: center;
        justify-content: center;
        color: white;
        font-size: 5vw;
        font-family: "Nunito sans",sans-serif;
        font-weight: bolder;
        border-radius: 1vw;
        background-color: #16AEE8;
        margin-left: 5%;
        margin-top: 10%;
        cursor: pointer;
    }

    .doorpricebox{
        width: 90%;
        height: 240vw;
        position: absolute;
        margin-top: 380%;
        margin-left: 5%;
   
        overflow: hidden;
    }
    .doortagimg{
      
       margin-top: 15%;
        width:110%;
        height: 110vw;
        margin-left: 8%;
        box-shadow: 2px 2px 10px -3px black;
    }
    .doorpriceinfobox{
        width: 110%;
        height: 40vw;
    
        position: absolute;
        margin-left: -5%;
        margin-top: 150%;
    }
    .doorphead{
        padding: 5%;
        font-size: 15vw;
        color: #006E99;
        font-weight: bolder;
        font-family: "Nunito sans ", sans-serif;
    }
    .dprice{
        font-size: 5vw;
        color: #16AEE8;
        margin-top: -8%;
        font-weight: bolder;
        margin-left: 5%;
    }
    .doorpdisc{
        font-size: 5vw;
        color: #16AEE8;
       
        font-weight: bold;
        margin-left: 5%;
    
    }
    .margin2{
        display: block;
    }
    .margin1{
        display: none;
    }
    .moreicon{
       display: none;
    }

}