.newusercintainer{
    position: absolute;
    width: 65%;
    height: 80vh;
    box-shadow: 2px 2px 12px -1px black;
    border-radius: .4vw;
    padding: 1%;
    margin-left: 26%;
    margin-top: 6%;
    background-color: #05242f;
    animation: shownewuser .4s   ease-in-out forwards;
}
@keyframes shownewuser {
    from{
      opacity: 0;
    }
    to{
   opacity: 1;
    }
    
}
.newuserhaed{
    color: white;
    font-size: 3vw;
    font-weight: bolder;
 
}
.userdetails{
    padding: 2%;
    width: 95%;
    height: 80%;
    border: 1px solid white;
    border-radius: .7vw;
}
.uinputs{
all: unset;
background-color: #05242f;
padding: 1%;
font-size: 1.5vw;
border: 1px solid white;
border-radius: .4vw;
width: 70%;
margin-top: 2%;
color: white;
}
.uinputs::placeholder{
    color: white;
}
.createnewuserbtn{
    display: flex;
    text-align: center;
    align-items: center;
    justify-content: center;
    font-size: 2vw;
    font-family: "Nunito sans", sans-serif;
    width: 30%;
    min-height: 2vw;
    height: auto;
    padding: 1%;
    color: white;
    background-color: #16AEE8;
    margin-top: 2%;
    border-radius: .4vw;
    cursor: pointer;
}
.successmessage{
    color: white;
    font-size: 1.2vw;
    margin-top: 1%;
    margin-left: 0%;
}

@media screen and (max-width:412px){
    .newusercintainer{
        position: absolute;
        width: 95%;
        height: auto;
        box-shadow: 2px 2px 12px -1px black;
        border-radius: .4vw;
        padding: 1%;
        margin-left: 1.5%;
        margin-top: 35%;
        background-color: #05242f;
        padding-bottom:8%;
        padding-top:4%;
        animation: shownewuser .4s   ease-in-out forwards;
        border-radius:2vw;
    }
    @keyframes shownewuser {
        from{
          opacity: 0;
        }
        to{
       opacity: 1;
        }
        
    }
    .newuserhaed{
        color: white;
        font-size: 5vw;
        font-weight: bolder;
     
    }
    .userdetails{
        padding: 2%;
        width: 95%;
        height: auto;
        border: 1px solid white;
        border-radius: .7vw;
        margin-top:4%
    }
    .uinputs{
    all: unset;
    background-color: #05242f;
    padding: 2%;
    font-size: 3vw;
    border: 1px solid white;
    border-radius: 1vw;
    width: 70%;
    margin-top: 2%;
    color: white;
    
    }
    .uinputs::placeholder{
        color: white;
        font-size:3vw
    }
    .createnewuserbtn{
        display: flex;
        text-align: center;
        align-items: center;
        justify-content: center;
        font-size: 3vw;
        font-family: "Nunito sans", sans-serif;
        width: 98%;
        height: 5vw;
        padding: 1%;
        color: white;
        background-color: #16AEE8;
        margin-top: 6%;
        border-radius: .4vw;
        cursor: pointer;
    }
    .successmessage{
        color: white;
        font-size: 2vw;
        margin-top: 1%;
        margin-left: 0%;
    }
}