.chatbotname{
    position: absolute;
    color: white;
    font-size: 1.1vw;
    margin-left: 3%;
    width: 100%;
    margin-top: 2%;
}
.backtobotdashboardicon{
    height: 2vw;
    width: 2vw;
    cursor: pointer;
    margin-right: 5%;
    margin-top: 0%;
}
.templatebody{
    max-width: 25vw;
    text-align: start;
    align-items: start;
    justify-content: start;
    background-color: #16AEE8;
    padding: 1%;
    padding-left: 2%;
    border-radius: .7vw;
}
.buttonsoftemplate{
    background-color: white;
    width: 97%;
    padding: 1%;
    height: auto;
    min-width: 4vh;
    text-align: center;
    align-items: center;
    justify-content: center;
    border-radius: .2vw;
    color: black;
    margin-top: 1%;
}

.lastmessagepreview{
    color: #16AEE8;
    
    width: 80%;
    margin: 2%;

}
@media screen and (max-width:412px) {

    .chatbotname{
    position: fixed;
    color: white;
    font-size: 4vw;
    margin-left: 0%;
    
    width: 100%;
    left: 5px;
    top: 2px;
}
.backtobotdashboardicon{
    height: 5vw;
    width: 5vw;
    cursor: pointer;
    margin-right: 5%;
    margin-top: 0%;
}
.templatebody{
    max-width: 50vw;
    text-align: start;
    align-items: start;
    justify-content: start;
    background-color: #16AEE8;
    padding: 1%;
    padding-left: 2%;
    border-radius: 2vw;
    font-weight: 500;
    font-size: 3.5vw;
    line-height: 1.3;
}
.buttonsoftemplate{
    background-color: white;
    width: 97%;
    padding: 1%;
    height: auto;
    min-width: 4vh;
    text-align: center;
    align-items: center;
    justify-content: center;
    border-radius: 2vw;
    color: black;
    margin-top: 1%;
}
.chatbotprofilename{
    font-size: 3vw;
    position: fixed;
    top: 8px;
}
.chatbotprofilephone{
    font-size: 2vw;
}
}