.navbarcontainer{
    position: absolute;
    width: 100%;
    height: 7vw;
    /* background-color: #041821; */
    background-color: transparent;
    position: fixed;
    z-index: 2000;
    /* box-shadow: 4px 4px 12px -3px black; */

}
.blurnavbar{
    position: absolute;
    width: 100%;
    height: 7vw;
    /* background-color: #041821; */
    background-color: transparent;
    position: fixed;
    z-index: 2000;
    backdrop-filter: blur(9px);
    animation: showblur 0s ease-in-out forwards;
}

@keyframes showblur {
    from{
      height: 0%;
    }
    to{
       height: 7vw;
    }
}
.navelements{
    font-weight: lighter;
    display: flex;
    width: 98.6%;
    height: 5.5vw;
    margin: .5%;
}
.navlogo{
    width: 18vw;
    height: 5.5vw;
    border-radius: .4vw;
    background-color: #0d303e;
}
.logo{
    margin-top: .1%;
    margin-left: 1%;
    position: absolute;
    height:5vw;
    width: 5.5vw;
    background-color: transparent;
    mix-blend-mode: multiply;
}
.companyname{
    color: white;
    font-size: 3.8vw;
margin-left: 35%;
margin-top: 3%;

}
.navs{
    font-family: "Nunito sans",sans-serif;
    position: absolute;
    margin-top: 3.2%;
    width: 70%;
    margin-left: 10%;
    display: flex;

}
.navele{
    margin-left: 2%;
    font-size: 1.6vw;
    color: #16AEE8;
    cursor: pointer;
}
.navicons{
    height: 3vw;
    width: 3vw;
    margin-left: 4%;
    z-index: 2000;
    margin-top: -10%;
}
.naviconinfo{
    margin-top: -10%;
    font-size: 1vw;
}
.navauths{
    display: flex;
    width: 25%;
    position: absolute;
    margin-left: 85%;
    margin-top: 3%;
}
.navlogin{
    border: 1px solid #16AEE8;
    width: 50%;
    height: 3vw;
    background-color: transparent;
    border-radius: .4vw;
    color: #16AEE8;
    font-size: 1.6vw;
    display: flex;
    text-align: center;
    align-items: center;
    justify-content: center;
    cursor: pointer;
}
.navsignup{
    width: 50%;
    height: 3vw;

    border-radius: .4vw;
    color: white;
    border: 1px solid white;
    font-size: 1.6vw;
    display: flex;
    text-align: center;
    align-items: center;
    justify-content: center;
    margin-left: 3%;
    cursor: pointer;

}
.navlogin:hover{
    background-color: #041821;
    border: 1px solid white;
    border-radius: .4vw;
    color: white;
    transition-duration: .4s;
}
.navsignup:hover{
    background-color: white;
    color: #041821;
    border: 1px solid white;
    border-radius: .4vw;
    transition-duration: .4s;
}

@media screen and (max-width:412px) {
     
    .navbarcontainer{
        position: absolute;
        width: 100%;
        height: 10vh;
        background-color: transparent;
        backdrop-filter: blur(8px);
        position: fixed;
        z-index: 2000;
        /* box-shadow: 4px 4px 12px -3px black; */
    
    }
    .blurnavbar{
        position: absolute;
        width: 100%;
        height: 0vh;
        background-color: transparent;
        position: fixed;
        z-index: 2000;
        backdrop-filter: blur(9px);
        animation: showblur 0s ease-in-out forwards;
    }
    .navelements{
        font-weight: lighter;
        display: flex;
        width: 98.6%;
        height: 5.5vw;
        margin: .5%;
    }
    .navlogo{
        width: 25vw;
        height: 10vw;
        border-radius: .4vw;
        background-color: #0d303e;
    }
    .logo{
      margin: 1.2%;
        position: absolute;
        height:15vw;
        width: 20vw;
    }
    .companyname{
        color: white;
        font-size: 3.8vw;
    margin-left: 35%;
    margin-top: 3%;
    
    }
    .navs{
        font-family: "Nunito sans",sans-serif;
        position: absolute;
        margin-top: 2%;
        width: 70%;
        margin-left: 10%;
        display: flex;
    
    }
    .navele{
        margin-left: 2%;
        font-size:3vw;
        color: white;
        cursor: pointer;
    }
    .navauths{
        display: flex;
        width: 25%;
        position: absolute;
        margin-left: 85%;
        margin-top: 1.4%;
    }
    .navlogin{
        border: 1px solid white;
        width: 50%;
        height: 3vw;
        background-color: white;
        border-radius: .4vw;
        color: #041821;
        font-size: 1.6vw;
        display: flex;
        text-align: center;
        align-items: center;
        justify-content: center;
        cursor: pointer;
    }
    .navsignup{
        width: 50%;
        height: 3vw;
    
        border-radius: .4vw;
        color: white;
        border: 1px solid white;
        font-size: 1.6vw;
        display: flex;
        text-align: center;
        align-items: center;
        justify-content: center;
        margin-left: 3%;
        cursor: pointer;
    
    }
    .navlogin:hover{
        background-color: #041821;
        border: 1px solid white;
        border-radius: .4vw;
        color: white;
        transition-duration: .4s;
    }
    .navsignup:hover{
        background-color: white;
        color: #041821;
        border: 1px solid white;
        border-radius: .4vw;
        transition-duration: .4s;
    }
    .homemenu{
        height: 15vw;
        width: 15vw;
        margin-left: 80vw;
        margin-top: 2%;
    }
    .logoname{
        position: absolute;
        color: #16AEE8;
        font-size: 20vw;
        font-weight: bolder;
    }
}