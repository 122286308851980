.actionbar{
    width: 4.9%;
   border-right: 1px solid white;
    background-color: #07161c;
    height: 100vh;
    position: absolute;
    z-index: 3;

}
.actionelements{
padding: 12%;
margin-top: 60%;
}
.hamburgermenu{
    height: 3.5vw;
    width: 3.5vw;
    margin-bottom: 120%;
    cursor: pointer;
}
.logout{
    height: 3.5vw;
    width: 3.5vw;
     cursor: pointer; 
}