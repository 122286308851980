.noemployeecontainer{
    width: 50%;
    height: 15vw;
    border-radius: .7vw;
    background-color: #d0f1ff;
    text-align: center;
    justify-content: center;
    align-items: center;
    position: absolute;
    margin-top: 13%;
    margin-left: 40%;
}
.noemployeehead{
    font-weight: bolder;
    font-size: 4vw;
    color: #054864;
    font-family: "Nunito sans", sans-serif;
    margin-top: 5%;
}
.noemployeeinfo{
    font-size: 1.5vw;
    color: #054864;
    font-family: "Nunito sans", sans-serif;
}
.pageheadcontainer{
    width: 65%;
    position: absolute;
    border-radius: .7vw;
    margin-left: 32.5%;
    margin-top:1%;
    height: 10vw;
    background-color: #054864;
    box-shadow: 2px 2px 12px -4px black;
   overflow: hidden;
   animation: routinganimation .4s   ease-in-out forwards;
}
@keyframes routinganimation {
    from{
        transform: translateX(-4vw);
        opacity: 0;

    }
    to{
        transform: translateX(0vw);
        opacity: 1;

    }
    
}
.pagedivider{
    background-color: white;
    border-bottom: 1.6px solid #054864;
}
.employeeprofilename{
margin-left: 2%;
font-size: 3vw;
font-weight: bolder;
color:#054864;
animation: nameanim .4s linear forwards; 

}
.employeeprofilephone{
    margin-left: 2%;
    margin-top: -1%;
font-size: 1.5vw;
color:#054864;
animation: nameanim .4s ease-in-out forwards;
}
@keyframes nameanim {
    from{
      opacity: 0;
    }
    to{
      opacity: 1;
    }
    
}
.mainemployeeactions{
    width: 100%;
    display: flex;
    position: absolute;
}
.removeemployeebtn{
    cursor: pointer;
    border-radius: .7vw;
    font-weight: bold;
    display: flex;
    text-align: center;
    align-items: center;
    justify-content: center;
    height: 4vw;
    width: 15%;
    font-size: 2vw;
    background-color: red;
    color: white;
    margin-right: 2%;
    margin-left: 60%;
    margin-top: 2%;
}
.removeemployeebtn:hover{
    transition-duration: .5s;
    background-color: rgb(184, 17, 17);
}
.makeadminbtn{
    cursor: pointer;
    border-radius: .7vw;
    font-weight: bold;
    display: flex;
    text-align: center;
    align-items: center;
    justify-content: center;
    height: 4vw;
    width: 20%;
    font-size: 2vw;
    background-color:#16AEE8;
    color: white;
    margin-top: 2%;
}
.makeadminbtn:hover{
    transition-duration: .5s;
    background-color: #0d7ba6;

}
.employeeactions{
    display: flex;
}
.action{
    color: white;
    font-size: 1.8vw;
    padding: 1%;
    margin-right: 2%;
    margin-left: 2%;
    cursor: pointer;
}
