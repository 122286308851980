.supersidebarcontainer{
    width: 30%;
    height: 100vh;
    position: fixed;
    background-color: #00618a;
    /* background-color: #07394f;   */
}
.supersidehead{
    display: flex;
    margin: 5%;
    width: 85%;
    height: 9vw;
}
.logo{
    width: 40%;
    height: 9v;
    
    background-color: white;
    border-radius: .4vw;
}
.mainlogo{
    width: 10vw;
    height: 10vw;
    margin-top: -1.5%;
    margin-left: .5%;
    position: absolute;
}
.logoname{
    font-size: 4vw;
    color: white;
    margin-left: 3%;
    margin-top: 1%;
}
.supernavcontainer{
    width: 100%;
    position: absolute;
    margin-top: 40%;
}
.supernavs{
    border-radius: .4vw;
    padding: 3%;
    width: 100%;
    height: 4vw;
    width: 80%;
    display: flex;
    cursor: pointer;
    margin-bottom: 5%;

}
.supericons{
    width: 4vw;
    height: 4vw;
}

.navname{
    color: white;
    font-family: "Nunito sans",sans-serif;
    font-size: 1.5vw;
    margin-top: 6%;
    margin-left: 10%;
}
.maindetailscontainer{
    margin-top: 5%;
}
.newcompanydetails{
    margin-top: 2%;
}
.newcomlpanyhead{
    font-size: 3vw;
    color: #00618a;
    font-weight: 600;
    border-bottom:2px solid #00618a;
}

.newcompanylabel{
    font-size: 1.5vw;
    color: #00618a;
    font-weight: 600;
}
.newcompanyinput{
    all: unset;
    background-color:white;
    width: 97.5%;
    font-size: 1.2vw;
    border-radius: .4vw;
    color: #00618a;
    padding: 1.4%;
    border: 1px solid #00618a;

}
.newcompanyinput::placeholder{
    color: #00618a;
}
.createnewcompanybtn{
    cursor: pointer;
    width: 96.5%;
    margin-top: 4%;
    height: 5vh;
    padding: 2%;
    display: flex;
    text-align: center;
    align-items: center;
    justify-content: center;
    font-size: 2vw;
    border-radius: .7vw;
    color: white;
    background-color: #16AEE8;
}
.createnewcompanybtn:hover{
    background-color: #00618a;
    transition-duration: .3s;
}
.signuperror{
    width: 60%;
    height: 4vh;
    padding: 1%;
    display: flex;
    text-align: center;
    align-items: center;
    justify-content: center;
    align-items: center;
    border: 1px solid red;
    border-radius: .7vw;
    color: red;
    font-size: 1vw;
    margin-top: 1%;
    margin-left: 17%;
}
.creationsuccess{
    width: 60%;
    height: 4vh;
    padding: 1%;
    display: flex;
    text-align: center;
    align-items: center;
    justify-content: center;
    align-items: center;
    border: 1px solid green;
    border-radius: .7vw;
    color: green;
    font-size: 1vw;
    margin-top: 1%;
    margin-left: 17%;
}