
a{
    text-decoration: none;
}

#mainhead{
    font-size:4vw;
    margin-top:5%;
    text-align:center;
    color:#16AEE8;
}

 h4{
    color:#16AEE8;
    /* text-align: center; */
    /* text-decoration: underline; */
    text-shadow: 0.2em 0.2em 0.5vw rgba(95, 127, 194, 0.3);
    font-size: 1.5vw;
}
ul li{
    list-style:outside;
    text-align: justify;
    font-size: 1.2vw;
}
p{
    text-align: justify;
    text-shadow: #cccccc;
    font-size: 1.2vw;
}
strong{
    text-decoration: underline;
}

@media (max-width: 768px) {
    #mainhead {
      font-size: 6vw;
    }
  }

  .privacycontainer , .termscontainer{
    padding: 2%;
  }