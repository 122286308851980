.App {

  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}


.switch {
  position: relative;
  display: inline-block;
  width: 4vw;
  height: 2vw;
}

/* Hide default HTML checkbox */
.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

/* The slider */
.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  -webkit-transition: .4s;
  transition: .4s;
}

.slider:before {
  position: absolute;
  content: "";
  height: 1.55vw;
  width: 1.55vw;
  left: 4px;
  bottom:3.3px;
  background-color: white;
  -webkit-transition: .4s;
  transition: .4s;
}

input:checked + .slider {
  background-color: #032939;
}

input:focus + .slider {
  box-shadow: 0 0 1px #032939;
}

input:checked + .slider:before {
  -webkit-transform: translateX(1.8vw);
  -ms-transform: translateX(1.8vw);
  transform: translateX(1.8vw);
}

/* Rounded sliders */
.slider.round {
  border-radius: 34px;
}

.slider.round:before {
  border-radius: 50%;
}

.popup-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5); /* Semi-transparent background */
  backdrop-filter: blur(8px); /* Apply blur effect */
  display: flex;
  justify-content: center;
  align-items: center;
}

.popup {
  border: 3px solid #16AEE8;
  height: 20vw;
  width: 40%;
  background-color: white;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.2);
}

.close-button {
  position: absolute;
  top: 10px;
  right: 10px;
}
.popuphead{
  font-size: 2vw;
  font-weight: bolder;
  color: #16AEE8;
  font-family: "Nunito sans",sans-serif;
}
.popuplables{
  font-size: 1.2vw;
  font-weight: bold;
  color: black;
  margin-top: 2%;

}
.buttoninput{
  all: unset;
  border-radius: .4vw;
  width: 70%;
  height: 2vw;
  color: black;
  padding: 2%;
  border: 1.5px solid #16AEE8;
}

.addbutton{
  border: 2px solid #16AEE8;
  color:white;
  border-radius: .4vw;
  background-color:#16AEE8;
  display: flex;
  text-align: center;
  align-items: center;
  justify-content: center;
  width: 30%;
  height: 3vw;
  font-size: 1.5vw;
  margin-top: 5%;

}

@media screen and (max-width:412px){
  *{
   font-weight: lighter;
  }
  .switch {
    position: relative;
    display: inline-block;
    width: 8vw;
    height: 4vw;
  }
  
  /* Hide default HTML checkbox */
  .switch input {
    opacity: 0;
    width: 0;
    height: 0;
  }
  
  /* The slider */
  .slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #ccc;
    -webkit-transition: .4s;
    transition: .4s;
  }
  
  .slider:before {
    position: absolute;
    content: "";
    height: 2.5vw;
    width: 2.5vw;
    left: 4px;
    bottom:3.3px;
    background-color: white;
    -webkit-transition: .4s;
    transition: .4s;
  }
  
  input:checked + .slider {
    background-color: #032939;
  }
  
  input:focus + .slider {
    box-shadow: 0 0 1px #032939;
  }
  
  input:checked + .slider:before {
    -webkit-transform: translateX(3vw);
    -ms-transform: translateX(3vw);
    transform: translateX(3vw);
  }
  
  /* Rounded sliders */
  .slider.round {
    border-radius: 34px;
  }
  
  .slider.round:before {
    border-radius: 50%;
  }
  
  .popup-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5); /* Semi-transparent background */
    backdrop-filter: blur(8px); /* Apply blur effect */
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .popup {
    border: 3px solid #16AEE8;
    height: 40vh;
    width: 80%;
    background-color: white;
    padding: 20px;
    border-radius: 8px;
 
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.2);
  }
  
  .close-button {
    position: absolute;
    top: 10px;
    right: 10px;
  }
  .popuphead{
    font-size: 5vw;
    font-weight: bolder;
    color: #16AEE8;
    font-family: "Nunito sans",sans-serif;
  }
  .popuplables{
    font-size: 4vw;
    font-weight: bold;
    color: black;
    margin-top: 2%;
  
  }
  .buttoninput{
    all: unset;
    border-radius: .4vw;
    width: 70%;
    height: 2vw;
    color: black;
    padding: 3%;
    border: 1.5px solid #16AEE8;
  }
  
  .addbutton{
    border: 2px solid #16AEE8;
    color:white;
    border-radius: .4vw;
    background-color:#16AEE8;
    display: flex;
    text-align: center;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 3vw;
    font-size: 3vw;
    margin-top: 5%;
  
  }
  
}